import { TFunction, i18n } from 'i18next';
import { PropsWithChildren, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import Moment from 'react-moment';

export interface DatatableProps {
    title?: string
    hydrate: (last: number) => Promise<Array<TableData>>
    height?: string
    textsize?: string
    headers: Array<{
        label: string,
        path: string,
        format?: 'text' | 'translated' | 'date' | 'datetime',
        translate?: boolean
        size: string
    }>
}

export interface TableData {
    id: number,
    [key: string]: number | string
}


const resolvePath = (t: TFunction, i18n: i18n, data: any, path: string, defaultValue: string, format: 'text' | 'translated' | 'date' | 'datetime'): ReactElement => {

    switch (format) {
        case 'translated':
            return <>{t(path.split('.').reduce((o, p) => o ? o[p] : defaultValue, data))}</>
        case 'date':
            return <Moment locale={i18n.language} format='L'>{path.split('.').reduce((o, p) => o ? o[p] : defaultValue, data)}</Moment>
        case 'datetime':
            return <Moment locale={i18n.language} format='L'>{path.split('.').reduce((o, p) => o ? o[p] : defaultValue, data)}</Moment>
        case 'text':
        default:
            return <>{path.split('.').reduce((o, p) => o ? o[p] : defaultValue, data)}</>
    }
}


export const Datatable = (props: PropsWithChildren<DatatableProps>) => {
    const [lines, setLines] = useState<Array<TableData>>([])
    const [last, setLast] = useState<number>(0)
    const lastLineRef = useRef<HTMLDivElement | null>(null);
    const [visible, setvisible] = useState<boolean>(false)
    const { t, i18n } = useTranslation()

    useEffect(() => {
        props.hydrate(last || 0).then((data: Array<TableData>) => {
            if (data.length == 0) return
            setLines([...lines, ...data])
            setLast(data[data.length - 1].id)
        })
    }, [visible])


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setvisible(entry.isIntersecting);
            },
            {
                root: null, // viewport
                rootMargin: '0px', // no margin
                threshold: 1,
            }
        );

        if (lastLineRef.current) {
            observer.observe(lastLineRef.current);
        }

        // Clean up the observer
        return () => {
            if (lastLineRef.current) {
                observer.unobserve(lastLineRef.current);
            }
        };
    }, []);


    return (
        <>
            <h2 className="tablet:text-2xl phone:text-xl font-bold tracking-tight text-gray-900 dark:text-gray-100 mb-1">
                {props.title}
            </h2>
            <div className={`${props.height || 'h-full'} flex flex-col`}>
                <div className='rounded flex flex-row w-full text-left text-xs font-bold bg-gray-100 text-gray-700 dark:bg-gray-700 text-gray-700 dark:text-gray-100 uppercase'>
                    {
                        props.headers.map((h, index) => (
                            <div key={index} className={`${h.size} px-4 py-3 bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-100 ${index == 0 ? 'rounded-tl-lg' : index == props.headers.length - 1 ? 'rounded-tr-lg' : ''}`}>
                                {h.label}
                            </div>
                        ))
                    }
                </div>
                <div className="flex-grow overflow-y-scroll overflow-x-hidden">
                    {
                        lines.map((l, index) => (
                            <div key={index} className={`flex flex-row border-b dark:border-gray-700 ${props.textsize || 'text-sm'} text-gray-700 dark:text-gray-200`}>
                                {
                                    props.headers.map((h, idx) => (
                                        <div key={idx} className={`${h.size} px-3 py-2 break-all`}>
                                            {
                                                resolvePath(t, i18n, l, h.path, '', h.format || 'text')
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                    <div ref={lastLineRef}></div>
                </div>
            </div>
        </>
    )
}

