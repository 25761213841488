import { PropsWithChildren } from "react"

export interface BoxSetProps {
    title: string,
}

export const BoxSet = (props: PropsWithChildren<BoxSetProps>) => {
    return (
        <div>
            <h2 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>{props.title}</h2>
            <div className='flex w-full flex-row gap-4 flex-wrap'>
                {props.children}
            </div>
        </div>
    )
}
