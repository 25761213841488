import { Avatar, Card } from 'flowbite-react'
import { getAvatarImage } from './AvatarImage'
import { ArrowDownTrayIcon, ClipboardIcon, TrashIcon } from '@heroicons/react/24/outline'
import { MarkdownRenderer } from './Markdown'
import { UserProfile } from 'oidc-client-ts'
import { useToast } from '../Contexts/ToastContext'

export interface ChatCardInterface {
    role: string,
    children: string
    className?: string
    user?: UserProfile
    remove?: () => void,
    download?: () => void
}

export const ChatCard = (props: ChatCardInterface) => {
    const toast = useToast()

    const copyToClipboard = (content: string) => {
        navigator.permissions.query({ name: 'clipboard-write' as PermissionName }).then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
                /* write to the clipboard now */
                navigator.clipboard.writeText(content)
                toast.fire('success', 'Content successfuly copied to clipboard', 1000)
            }
            else {
                toast.fire('failure', 'Cannot copy content to clipboard, please check you navigator permisions', 3000)
            }
        });
    }

    return (
        <>
            <Card className={`w-full my-2 border text-gray-900 dark:text-gray-200 border-gray-300 bg-white dark:border-white dark:bg-gray-800 ${props.className} gap-0`}>

                <div className='flex flex-row items-start justify-begin'>
                    {
                        props.role === 'user' ?
                            <Avatar className='mr-2 ml-2 h-8 basis-8 grow-0 shrink-0' size={'sm'} alt="" img={getAvatarImage(props.user?.given_name, props.user?.family_name, 64)} rounded />
                            :
                            <Avatar className='mr-2 ml-2 h-8 basis-8 grow-0 shrink-0' size={'sm'} alt="" img={getAvatarImage('G', 'o', 64)} rounded />
                    }
                    {
                        <div className={`flex-grow text-sm overflow-x-scroll border-0 rounded-md border ${props.role === 'user' ? 'font-sans' : 'font-mono'}`}>
                            <MarkdownRenderer>{props.children}</MarkdownRenderer>
                        </div>
                    }
                </div>
                <div className='flex flex-row items-start justify-end'>
                    <div className='mr-1 ml-1 h-8 basis-8 grow-0 shrink-0'></div>
                    <div className='mr-2 ml-2 basis-16 grow-0 shrink-0 items-start'>
                        <div className='flex flex-row gap-4'>
                            {
                                props.remove ?
                                    <TrashIcon className='h-5 w-5 text-red-500 hover:text-red-400 dark:text-red-500 dark:hover:text-red-400 cursor-pointer' onClick={props.remove}></TrashIcon>
                                    : <></>
                            }
                            {
                                props.download ?
                                    <ClipboardIcon className='h-5 w-5 text-gray-500 hover:text-gray-400 dark:text-gray-200 hover:dark:text-gray-100 cursor-pointer' onClick={() => { copyToClipboard(props.children.replace(/\n$/, '')) }}></ClipboardIcon>
                                    : <></>
                            }
                            {
                                props.download ?
                                    <ArrowDownTrayIcon className='h-5 w-5 text-gray-500 hover:text-gray-400 dark:text-gray-200 hover:dark:text-gray-100 cursor-pointer' onClick={props.download}></ArrowDownTrayIcon>
                                    : <></>

                            }
                        </div>

                    </div>
                </div>

            </Card>
        </>
    )
}