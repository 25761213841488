// React
import { useEffect, useRef, useState } from 'react'
import { useAuth } from 'react-oidc-context'

// Icons
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'

// Project components & Templates
// Project services & context
import { Avatar, TextInput } from 'flowbite-react'
import { getAvatarImage } from '../../../components/Elements/AvatarImage'
import { MarkdownRenderer } from '../../../components/Elements/Markdown'
import { insert, select, useDb } from '../../../services/Db'
import { PostgrestSingleResponse } from '@supabase/postgrest-js'
import useWebSocket, { ReadyState } from "react-use-websocket"
import { PageTitle } from '../../../components/Elements/Title'
import { WorkspaceProps } from '../Index'
import { UserHasRights } from '../../../services/Workspace'
import { DbRights } from '../../../config/Db'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'


export default function WorkspaceChat(props: WorkspaceProps) {

    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)
    const { t, i18n } = useTranslation()

    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        `${import.meta.env.VITE_WS_URL}/workspaces/${props.workspace_ref}/notifications/app.chat_message`,
        {
            share: false,
            shouldReconnect: () => true,
            onClose: () => { }
        },
    )


    /* Chat */
    const messageInputRef = useRef<any>({})
    const messagesEndRef = useRef<HTMLDivElement>(null)

    const [messages, setMessages] = useState<Array<any>>([])

    /*******************************************************************
     * Chat handlers                                                   *
     *******************************************************************/

    const sendMessage = () => {
        if (messageInputRef.current.value.length > 0 && props.workspaceConfig?.workspace && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite])) {
            insert(db, 'chat_message', {
                workspace_id: props.workspaceConfig.workspace.id,
                content: messageInputRef.current.value
            }).then(() => {
            })
            messageInputRef.current.value = ''
        }
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'instant' })
    }

    const collectMessages = (last: number) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatRead, DbRights.SettingsChatReadWrite])) {
            if (last > 0) {
                select(db, 'chat_message', 'id,content,created_at,user(first_name,last_name)')
                    .eq('workspace_id', props.workspaceConfig?.workspace.id)
                    .gt('id', last)
                    .order('id')
                    .then((result: PostgrestSingleResponse<any[]>) => {
                        if (result.error) {
                            console.error(result.error.details)
                        } else {
                            setMessages([...messages, ...result.data.reverse()])
                        }
                    })
            } else {
                select(db, 'chat_message', 'id,content,created_at,user(first_name,last_name)')
                    .eq('workspace_id', props.workspaceConfig?.workspace.id)
                    .order('created_at', { ascending: false })
                    .limit(100)
                    .then((result: PostgrestSingleResponse<any[]>) => {
                        if (result.error) {
                            console.error(result.error.details)
                            setMessages([])
                        } else {
                            setMessages(result.data.reverse())
                        }
                    })
            }
        }
    }

    /*******************************************************************
     * Triggers                                                        *
     *******************************************************************/
    useEffect(() => {
        if (auth.user && props.workspaceConfig?.workspace.id) {
            collectMessages(0)
        }
    }, [auth.user, props.workspaceConfig]);

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendJsonMessage({
                auth: { token: auth.user?.access_token },
                content: ''
            })
        }
    }, [readyState, auth.user])

    useEffect(() => {
        const last = messages[messages.length - 1]
        if (last && last.id > 0) {
            collectMessages(last.id)
        } else {
            collectMessages(0)

        }
    }, [lastJsonMessage])


    useEffect(() => {
        scrollToBottom()
    }, [messages]);



    return (
        <>
            {
                UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatRead, DbRights.SettingsChatReadWrite]) ?
                    <>
                        <PageTitle subtitle={t('settings.chat.subtitle')}>{t('settings.chat.title')}</PageTitle>
                        <div className='flex flex-col flex-grow overflow-y-scroll mt-4'>
                            {

                                messages.map((message, index) => (
                                    <div key={index} className='flex flex-row items-start justify-begin dark:hover:bg-gray-700 hover:bg-gray-100 py-2 rounded-md'>
                                        <Avatar className='mr-2 ml-2 h-8 basis-8 grow-0 shrink-0' size={'sm'} alt="User settings" img={getAvatarImage(message.user.first_name, message.user.last_name, 64)} rounded />
                                        <div className='flex-grow text-sm overflow-x-scroll px-2 border-0 rounded-md border'>
                                            <div className='flex flex-col justify-begin'>
                                                <div className='font-bold text-gray-900 dark:text-gray-100'>{message.user.first_name} {message.user.last_name} <span className='font-thin text-xs'><Moment locale={i18n.language} format='LLL'>{message.created_at}</Moment></span></div>
                                                <MarkdownRenderer>{message.content}</MarkdownRenderer>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <div ref={messagesEndRef} className='justify-end items-end'></div>
                        </div>
                        <div className="flex justify-center bottom-4 w-full bg-transparent">
                            <TextInput
                                id='question'
                                name='question'
                                autoFocus={true}
                                defaultValue=''
                                disabled={readyState !== ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite])}
                                ref={messageInputRef}
                                onKeyDown={event => event.key === 'Enter' ? sendMessage() : ''}
                                color='gray'
                                className='mb-2 mx-4 shadow-lg w-2/3'
                                sizing='lg'
                                rightIcon={PaperAirplaneIcon}
                            >
                            </TextInput>
                        </div>
                    </>
                    : <></>
            }
        </>
    )
}