import { PostgrestClient, PostgrestSingleResponse } from "@supabase/postgrest-js"
import { select } from "./Db"
import { DbRightInterface, DbRoleInterface, DbUserWorkspaceInterface, DbWorkspaceInterface } from "../config/Db"


export const getWorkspaces = async (db: PostgrestClient<any, string, any>, user_ref: string): Promise<Array<any>> => {
    return select(db, 'user_workspace', 'ref,user(ref),role(name),workspace(id,ref,name)')
        .eq('user_ref', user_ref)
        .then((result: PostgrestSingleResponse<any>) => {
            if (result.error) {
                console.error(result.error.details)
                return []
            } else {
                return result.data
            }
        })
}

export const getWorkspace = async (db: PostgrestClient<any, string, any>, user_ref: string, workspace_ref: string): Promise<any> => {
    return select(db, 'user_workspace', 'ref,user!inner(username),role(name,role_right(right(name))),workspace!inner(id,ref,name)')
        .eq('user_ref', user_ref)
        .eq('workspace.ref', workspace_ref)
        .single()
        .then((result: PostgrestSingleResponse<any>) => {
            if (result.error) {
                console.error(result.error.details)
                return {}
            } else {
                return result.data
            }
        })
}

export const UserHasRights = (role: DbRoleInterface, rights: Array<string>): boolean => {
    return role && RoleHasRights(role.role_right, rights)
}

export const RoleHasRights = (role_rights: Array<{ right: DbRightInterface }>, rights: Array<string>): boolean => {
    return role_rights.length > 0 && role_rights.filter((r: { right: DbRightInterface }) => {
        return rights.includes(r.right.name)
    }).length > 0
}

export const getWorkspaceMembers = async (db: PostgrestClient<any, string, any>, workspace: DbWorkspaceInterface): Promise<Array<DbUserWorkspaceInterface>> => {
    const result: any = await select(db, 'user_workspace', 'id,ref,created_at,workspace_id,workspace(name,ref),role(id,name,role_right(right(name))),user(first_name,last_name,email,ref)')
        .eq('workspace_id', workspace.id)

    if (result.error) {
        return Promise.reject()
    }
    else {
        return Promise.resolve(result.data)
    }
}