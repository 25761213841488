
const readStorage = (key:string) => {
    if (typeof localStorage !== 'undefined') {
        const item = localStorage.getItem(key)
        if (!item) {
            return {}
        }
        else {
            return JSON.parse(item)
        }
    }
    else {
        return {}
    }
}

const writeStorage = (key:string, data:object) => {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem(key, JSON.stringify(data))
        return data
    }
    else {
        return {}
    }
} 

const deleteStorage = (key:string) => {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(key)
    }
}

export {
    readStorage,
    writeStorage,
    deleteStorage,
}