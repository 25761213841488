// React
// Flowbite & icons
import { Alert, Button } from 'flowbite-react'
import { ArrowLeftIcon, ShieldExclamationIcon } from '@heroicons/react/24/outline'
// External packages
import { useTranslation } from 'react-i18next';
// Services
// Components
import { Page } from '../components/Layout/Page'
import { Link } from 'react-router-dom';
// Config


export default function Index() {
    const { t } = useTranslation()

    return (
        <Page hcenter={true} vcenter={true}>
            <div className='flex flex-col gap-8'>
                <div>
                    <Alert color="failure" icon={ShieldExclamationIcon}>
                        <span className="font-bold">{t('general.denied.title')} :</span> {t('general.denied.description')}
                    </Alert>
                </div>
                <div>
                    <div className="mt-10 flex items-center justify-center gap-x-10">
                        <Button color='action' as={Link} to='/'>
                            <ArrowLeftIcon className="h-5 w-5 mr-2" />
                            {t('general.backtohome')}
                        </Button>
                    </div>
                </div>
            </div>
        </Page>
    )
}
