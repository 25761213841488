import { User, WebStorageStateStore } from 'oidc-client-ts'

const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
}


const RegisterUrl = (redirect_path: string, lang: string): string => {
    return `${import.meta.env.VITE_AUTH_SERVER_URL}/realms/${import.meta.env.VITE_AUTH_SERVER_REALM}/protocol/openid-connect/registrations?client_id=${import.meta.env.VITE_AUTH_SERVER_CLIENT_ID}&response_type=code&scope=openid&ui_locales=${lang}&redirect_uri=${window.location.origin}${redirect_path}`
}

const oidcConfig = (origin: string): any => {
    return {
        authority: `${import.meta.env.VITE_AUTH_SERVER_URL}/realms/${import.meta.env.VITE_AUTH_SERVER_REALM}`,
        client_id: import.meta.env.VITE_AUTH_SERVER_CLIENT_ID,
        redirect_uri: `${origin}/`,
        onSigninCallback: onSigninCallback,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    }
}


export {
    oidcConfig,
    onSigninCallback,
    RegisterUrl
}