// React
import { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import { useAuth } from "react-oidc-context";
import { useTranslation } from "react-i18next";
// Flowbite & Icons
import { Avatar, Badge, Button, Checkbox, Dropdown } from "flowbite-react";
import { ChevronDownIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
// External packages
import { PostgrestSingleResponse } from "@supabase/postgrest-js";
// Services
import { getWorkspaceMembers, RoleHasRights, UserHasRights } from "../../../services/Workspace";
import { bulkinsert, insert, remove, select, update, useDb } from "../../../services/Db";
// Components
import { PageTitle } from "../../../components/Elements/Title";
import { BoxSet } from "../../../components/Layout/BoxSet";
import { Box } from "../../../components/Elements/Box";
import { NameEditable } from "../../../components/Elements/NameEditable";
import { getAvatarImage } from "../../../components/Elements/AvatarImage";
import { WorkspaceProps } from "../Index";
import { useToast } from "../../../components/Contexts/ToastContext";
// Config
import { DbRightInterface, DbRights, DbRoleInterface, DbUserWorkspaceInterface } from "../../../config/Db";


export default function WorkspaceRights(props: WorkspaceProps) {

    const auth = useAuth()
    const toast = useToast()
    const db = useDb('app', auth.user?.access_token)

    const [roles, setRoles] = useState<Array<DbRoleInterface>>([])
    const [rightIds, setRightIds] = useState<{ [key: string]: number }>({})
    const [rightLabels, setRightLabels] = useState<Array<string>>([])

    const [userWorkspaces, setUserWorkspaces] = useState<Array<DbUserWorkspaceInterface>>([])
    const { t, i18n } = useTranslation()


    const updateRoles = () => {
        if (props.workspaceConfig && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite, DbRights.SettingsRightsRead])) {
            setRoles([])
            select(db, 'role', 'id,name,workspace(id),role_right(right(name))')
                .or(`workspace_id.is.null,workspace_id.eq.${props.workspaceConfig.workspace.id}`)
                .order('id', { ascending: false })
                .then((result: PostgrestSingleResponse<any[]>) => {
                    if (result.error) {
                        console.error(result.error.details)
                        setRoles([])
                    } else {
                        setRoles(result.data)
                    }
                })
        } else {
            setRoles([])
        }
    }

    const updateWorkspaceMembers = () => {
        if (props.workspaceConfig?.workspace && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsMemberRead, DbRights.SettingsMemberReadWrite])) {
            getWorkspaceMembers(db, props.workspaceConfig.workspace)
                .then((members: Array<DbUserWorkspaceInterface>) => setUserWorkspaces(members))
                .catch(() => setUserWorkspaces([]))
        } else {
            setUserWorkspaces([])
        }
    }

    const handleDuplicate = (role: DbRoleInterface) => {
        if (props.workspaceConfig && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite])) {
            insert(db, 'role', {
                workspace_id: props.workspaceConfig?.workspace.id,
                name: t('settings.rights.sections.roles.copy', { role: t(role.name) }),
                created_at: new Date()
            }).single().then((result: PostgrestSingleResponse<any>) => {
                if (result.error) {
                    toast.fire('failure', t('settings.rights.sections.roles.duplicate.failure', { role: t(role.name) }), 3000)
                } else {
                    const newrole = result.data as DbRoleInterface
                    const newrightroles = role.role_right?.map((value: { right: DbRightInterface }) => {
                        return {
                            role_id: newrole.id,
                            right_id: rightIds[value.right.name],
                            created_at: new Date()
                        }
                    })
                    bulkinsert(db, 'role_right', newrightroles).then((_: PostgrestSingleResponse<any>) => {
                        toast.fire('success', t('settings.rights.sections.roles.duplicate.success', { role: t(role.name) }), 3000)
                        updateRoles()
                    })
                }
            })
        } else {
            toast.fire('failure', t('settings.rights.sections.roles.duplicate.right'), 3000)
        }
    }

    const handleDelete = (role: DbRoleInterface) => {
        if (props.workspaceConfig && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite])) {
            remove(db, 'role').eq('id', role.id).single().then((result: PostgrestSingleResponse<any>) => {
                if (result.error) {
                    toast.fire('failure', t('settings.rights.sections.roles.delete.failure', { role: t(role.name) }), 3000)
                } else {
                    toast.fire('success', t('settings.rights.sections.roles.delete.success', { role: t(role.name) }), 3000)
                    updateRoles()
                }
            })
        } else {
            toast.fire('failure', t('settings.rights.sections.roles.delete.right'), 3000)
        }
    }

    const handleRightClick = (role: DbRoleInterface, right: string, checked: boolean) => {
        if (props.workspaceConfig && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite])) {

            if (checked == false) {
                remove(db, 'role_right')
                    .eq('role_id', role.id || 0)
                    .eq('right_id', rightIds[right])
                    .then((result: any) => {
                        if (result.error) {
                            toast.fire('failure', t('settings.rights.sections.roles.unchecked.failure', { right: right, role: t(role.name) }), 3000)
                        } else {
                            toast.fire('success', t('settings.rights.sections.roles.unchecked.success', { right: right, role: t(role.name) }), 3000)
                        }
                    })
            } else {
                insert(db, 'role_right', {
                    role_id: role.id,
                    right_id: rightIds[right]
                }).single().then((result: any) => {
                    if (result.error) {
                        toast.fire('failure', t('settings.rights.sections.roles.checked.failure', { right: right, role: t(role.name) }), 3000)
                    } else {
                        toast.fire('success', t('settings.rights.sections.roles.checked.success', { right: right, role: t(role.name) }), 3000)
                    }
                })
            }
        } else {
            toast.fire('failure', t('settings.rights.sections.roles.checked.right'), 3000)
        }
    }

    const handleUserRoleChange = (user_workspace_id: number | undefined, role_id: number | undefined) => {
        if (props.workspaceConfig && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite])) {
            if (user_workspace_id && role_id) {
                update(db, 'user_workspace', {
                    role_id: role_id
                }).eq('id', user_workspace_id).single()
                    .then((result: PostgrestSingleResponse<any>) => {
                        if (result.error) {
                            toast.fire('failure', t('settings.rights.sections.users.userrolechange.failure'), 3000)
                        } else {
                            toast.fire('success', t('settings.rights.sections.users.userrolechange.success'), 3000)
                            updateWorkspaceMembers()
                        }
                    })
            }
        } else {
            toast.fire('failure', t('settings.rights.sections.users.userrolechange.right'), 3000)

        }
    }


    const saveTitle = (role_id: number, title: string) => {
        if (props.workspaceConfig && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite])) {
            update(db, 'role', {
                name: title
            }).eq('id', role_id).then(() => {
                updateRoles()
                updateWorkspaceMembers()
            })
        }
    }

    const roleUsed = (role: DbRoleInterface): Array<DbUserWorkspaceInterface> => {
        return userWorkspaces.filter((userWorkspace: DbUserWorkspaceInterface) => { return userWorkspace.role.id == role.id })
    }

    useEffect(() => {
        const updateRights = () => {
            if (props.workspaceConfig && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite, DbRights.SettingsRightsRead])) {
                select(db, 'right', 'id,name').order('id', { ascending: true }).then((result: PostgrestSingleResponse<any[]>) => {
                    if (result.error) {
                        console.error(result.error.details)
                        setRightIds({})
                    } else {
                        setRightIds(Object.fromEntries(result.data.map((value: DbRightInterface) => {
                            return [value.name, value.id || 0]
                        })))
                        setRightLabels(Array.from(new Set(result.data.map((value: DbRightInterface) => {
                            return value.name.replace(/Read(?:Write)?$/, '')
                        }))))
                    }
                })
            } else {
                setRightIds({})
                setRightLabels([])
            }
        }
        updateRights()
        updateWorkspaceMembers()
    }, [props.workspaceConfig, auth.user])

    useEffect(() => {
        if (rightLabels.length > 0) {
            updateRoles()
        }
    }, [rightIds])

    return (
        <>
            {
                UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite, DbRights.SettingsRightsRead]) ?
                    <>
                        <PageTitle subtitle={t('settings.rights.subtitle')}>{t('settings.rights.title')}</PageTitle >
                        <div className='py-4'>
                            <BoxSet title={t('settings.rights.sections.users.title')}>
                                {
                                    userWorkspaces.map((userWorkspace, index) => (
                                        <Box
                                            key={index}
                                            size='min-w-80'
                                            title={
                                                <div className='flex flex-row gap-8 items-center justify-between'>
                                                    <div className='flex flex-row gap-2 items-center'>
                                                        <Avatar size={'xs'} alt="User name" img={getAvatarImage(userWorkspace.user.first_name, userWorkspace.user.last_name, 48)} rounded />
                                                        {userWorkspace.user.first_name} {userWorkspace.user.last_name}
                                                    </div>
                                                </div>
                                            }
                                            subtitle={userWorkspace.user.email}
                                            action={
                                                userWorkspace.user.ref != auth.user?.profile.sub && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite]) ?
                                                    <Dropdown
                                                        hidden={true}
                                                        label=""
                                                        renderTrigger={
                                                            () =>
                                                                <button id='select-role' className='w-full text-sm font-bold text-gray-900 dark:text-gray-200 truncate'>
                                                                    {t(userWorkspace.role.name)}
                                                                    <ChevronDownIcon className='ml-2 h-4 w-4 inline'></ChevronDownIcon>
                                                                </button>
                                                        }
                                                    >
                                                        {
                                                            roles.map((role, idx) => (
                                                                <Fragment key={idx}>
                                                                    {
                                                                        role.name != userWorkspace.role.name ?
                                                                            <Dropdown.Item onClick={() => handleUserRoleChange(userWorkspace.id, role.id)}>{role.name == 'roles.owner' ? <ExclamationTriangleIcon className='h-4 w-4 text-red-700 dark:text-red-500 mr-2' /> : <></>}{t(role.name)}</Dropdown.Item>
                                                                            : <></>

                                                                    }
                                                                </Fragment>
                                                            ))
                                                        }
                                                    </Dropdown> : <></>
                                            }
                                        >
                                            <div className="flex flex-col gap-2">
                                                <div className='text-gray-900 dark:text-gray-100 italic text-sm'>{t('settings.rights.sections.users.membersince')} <Moment locale={i18n.language} fromNow ago>{userWorkspace.created_at}</Moment></div>
                                                <div className='flex flex-row gap-2'>
                                                    <Badge color='blue'>{t(userWorkspace.role.name)}</Badge>
                                                    {
                                                        userWorkspace.user.ref == auth.user?.profile.sub ?
                                                            <Badge className='justify-center' color="green">{t('general.me')}</Badge>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                        </Box>
                                    ))
                                }
                            </BoxSet>
                        </div>
                        <div className='py-4'>
                            <BoxSet title={t('settings.rights.sections.roles.title')}>
                                {
                                    roles.map((role, index) => (
                                        <Box size='min-w-80 flex-grow' key={index} title={
                                            <div className="flex flex-row gap-4 items-center">
                                                {
                                                    role.workspace && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite]) ?
                                                        <div><NameEditable identifier={role.id} save={saveTitle} name={t(role.name)}></NameEditable></div>
                                                        :
                                                        <>{t(role.name)}</>
                                                }
                                                <div>{role.workspace ? <Badge color='red'>{t('settings.rights.sections.roles.custom')}</Badge> : <Badge color='yellow'>{t('settings.rights.sections.roles.default')}</Badge>}</div>
                                            </div>
                                        } action={
                                            UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite]) ?
                                                <div className="flex flex-col gap-2 items-end">
                                                    <div>
                                                        {
                                                            roleUsed(role).length > 0 ?
                                                                <span className="text-sm italic text-gray-400 dark:text-gray-200">
                                                                    {t('settings.rights.sections.roles.usedby')} {roleUsed(role).map((uw, i) => (<span key={i}>{uw.user.first_name} {uw.user.last_name}{`${i < roleUsed(role).length - 1 ? ', ' : ''}`}</span>))}
                                                                </span> : <></>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            role.workspace ?
                                                                roleUsed(role).length == 0 ?
                                                                    <Button onClick={() => handleDelete(role)} size='xs' color='emergency'>{t('settings.rights.sections.roles.delete.button')}</Button> : <></>
                                                                :
                                                                <Button onClick={() => handleDuplicate(role)} color='action' size='xs'>{t('settings.rights.sections.roles.duplicate.button')}</Button>
                                                        }
                                                    </div>
                                                </div>
                                                : <></>
                                        }>
                                            <div className='max-h-80 flex flex-col'>
                                                <div className='rounded flex flex-row items-center w-full text-left text-xs font-bold bg-gray-100 text-gray-700 dark:bg-gray-700 text-gray-700 dark:text-gray-100 uppercase'>
                                                    <div key='Right' className='w-1/2 px-4 py-3 bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-100 rounded-tl-lg'>{t('settings.rights.sections.roles.right')}</div>
                                                    <div key='Read' className='w-1/4 px-4 py-3 bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-100'>{t('settings.rights.sections.roles.read')}</div>
                                                    <div key='ReadWrite' className='w-1/4 px-4 py-3 bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-100 rounded-tr-lg'>{t('settings.rights.sections.roles.readwrite')}</div>
                                                </div>
                                                <div className="flex-grow overflow-y-scroll overflow-x-hidden text-sm">
                                                    <form>
                                                        {
                                                            Array.from(rightLabels).map((right, idx) => (
                                                                <div key={idx} className='flex flex-row border-b dark:border-gray-700 text-sm text-gray-700 dark:text-gray-200'>
                                                                    <div className='w-1/2 px-3 py-1 break-all font-mono'>{right}</div>
                                                                    <div className='w-1/4 px-3 py-1 break-all'>
                                                                        {
                                                                            rightIds[`${right}Read`] === undefined ?
                                                                                <></>
                                                                                :
                                                                                RoleHasRights(role.role_right, [`${right}Read`]) === true ?
                                                                                    <Checkbox
                                                                                        id={`${role.name}${right}Read`}
                                                                                        color={role.workspace === null || !UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite]) ? 'disabled' : 'default'}
                                                                                        onClick={(event) => handleRightClick(role, `${right}Read`, (event.target as HTMLInputElement).checked)}
                                                                                        disabled={role.workspace === null || !UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite])}
                                                                                        defaultChecked />
                                                                                    :
                                                                                    <Checkbox
                                                                                        id={`${role.name}${right}Read`}
                                                                                        color={role.workspace === null || !UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite]) ? 'disabled' : 'default'}
                                                                                        onClick={(event) => handleRightClick(role, `${right}Read`, (event.target as HTMLInputElement).checked)}
                                                                                        disabled={role.workspace === null || !UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite])}
                                                                                    />
                                                                        }
                                                                    </div>
                                                                    <div className='w-1/4 px-3 py-1 break-all'>
                                                                        {
                                                                            rightIds[`${right}ReadWrite`] === undefined ?
                                                                                <></>
                                                                                :
                                                                                RoleHasRights(role.role_right, [`${right}ReadWrite`]) === true ?
                                                                                    <Checkbox
                                                                                        id={`${role.name}${right}ReadWrite`}
                                                                                        color={role.workspace === null || !UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite]) ? 'disabled' : 'default'}
                                                                                        onClick={(event) => handleRightClick(role, `${right}ReadWrite`, (event.target as HTMLInputElement).checked)}
                                                                                        disabled={role.workspace === null || !UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite])}
                                                                                        defaultChecked
                                                                                    />
                                                                                    :
                                                                                    <Checkbox
                                                                                        id={`${role.name}${right}ReadWrite`}
                                                                                        color={role.workspace === null || !UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite]) ? 'disabled' : 'default'}
                                                                                        onClick={(event) => handleRightClick(role, `${right}ReadWrite`, (event.target as HTMLInputElement).checked)}
                                                                                        disabled={role.workspace === null || !UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsRightsReadWrite])}
                                                                                    />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </Box>
                                    ))
                                }
                            </BoxSet>
                        </div>
                    </>
                    : <></>
            }
        </>
    )
}