import { ApiAction, HttpReturnCode, ApiErrorType } from "./Api"


export interface HttpApiResponse {
    type: ApiErrorType,
    title: string,
    detail: string,
    status: number
}


/**
 * 
 * @param method action to use as HTTP method for API
 * @param token user token
 * @returns the fetch option object
 */
const createFetchOptions = (method: string, token: string): any => {
    return {
        method: method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    }
}


/**
 * 
 * @param data data object to post
 * @param token user access token used to authenticate user
 */
export const httpApiPost = (path: string, data: any | ArrayBuffer, token: string): Promise<HttpApiResponse | any> => {
    const url = `${import.meta.env.VITE_HTTP_URL}${path}`

    return new Promise((resolve, reject) => {
        return fetch(url, {
            ...createFetchOptions(ApiAction.CREATE, token),
            body: (data instanceof ArrayBuffer) ? data : JSON.stringify(data)
        }).then((response: Response) => {
            switch (response.status) {
                case HttpReturnCode.CREATED: {
                    return resolve(response.json())
                }
                case HttpReturnCode.OK: {
                    return resolve(response.json())
                }
                case HttpReturnCode.CONFLICT: {
                    const json: any = response.json()
                    return reject({
                        type: ApiErrorType.DUPLICATE,
                        title: json.title,
                        detail: json.details,
                        status: response.status
                    })
                }
                case HttpReturnCode.NOTFOUND: {
                    const json: any = response.json()
                    return reject({
                        type: ApiErrorType.NOTFOUND,
                        title: json.title,
                        detail: json.details,
                        status: response.status
                    })
                }
                case HttpReturnCode.METHOD_NOT_ALLOWED: {
                    return reject({
                        type: ApiErrorType.METHOD_NOT_ALLOWED,
                        title: 'Method not allowed',
                        detail: 'POST method is not allowed',
                        status: response.status
                    })
                }
                default: {
                    reject({
                        type: ApiErrorType.INTERNAL,
                        title: 'Error during request processing',
                        detail: 'Internal error',
                        status: HttpReturnCode.INTERNAL
                    })
                }
            }
        }).catch(() => {
            reject({
                type: ApiErrorType.INTERNAL,
                title: 'Error during request processing',
                detail: 'Internal error',
                status: HttpReturnCode.INTERNAL
            })
        })
    })
}


/**
 * 
 * @param data data object to post
 * @param token user access token used to authenticate user
 */
export const httpApiGet = (path: string, token: string): Promise<HttpApiResponse | any> => {
    const url = `${import.meta.env.VITE_HTTP_URL}${path}`

    return new Promise((resolve, reject) => {
        fetch(url, {
            ...createFetchOptions(ApiAction.GET, token),
        }).then((response: Response) => {
            switch (response.status) {
                case HttpReturnCode.OK: {
                    return resolve(response.json())
                }
                case HttpReturnCode.NODATA: {
                    return resolve({})
                }
                case HttpReturnCode.NOTFOUND: {
                    const json: any = response.json()
                    return reject({
                        type: ApiErrorType.NOTFOUND,
                        title: json.title,
                        detail: json.details,
                        status: response.status
                    })
                }
                default: {
                    return reject({
                        type: ApiErrorType.INTERNAL,
                        title: 'Error during json response decoding',
                        detail: 'Internal error',
                        status: HttpReturnCode.INTERNAL
                    })
                }
            }
        })
            .catch(() => {
                return reject({
                    type: ApiErrorType.INTERNAL,
                    title: 'Error during json response decoding',
                    detail: 'Internal error',
                    status: HttpReturnCode.INTERNAL
                })
            })
    })
}

/**
 * 
 * @param data data object to post
 * @param token user access token used to authenticate user
 */
export const httpApiDelete = async (path: string, token: string): Promise<HttpApiResponse | any> => {
    const url = `${import.meta.env.VITE_HTTP_URL}${path}`

    return new Promise((resolve, reject) => {
        fetch(url, {
            ...createFetchOptions(ApiAction.DELETE, token),
        }).then((response: Response) => {
            switch (response.status) {
                case HttpReturnCode.OK: {
                    return resolve(response.json())
                }
                case HttpReturnCode.NODATA: {
                    return resolve({})
                }
                case HttpReturnCode.METHOD_NOT_ALLOWED: {
                    return reject({
                        type: ApiErrorType.METHOD_NOT_ALLOWED,
                        title: 'Method not allowed',
                        detail: 'POST method is not allowed',
                        status: response.status
                    })
                }
                case HttpReturnCode.NOTFOUND: {
                    const json: any = response.json()
                    return reject({
                        type: ApiErrorType.NOTFOUND,
                        title: json.title,
                        detail: json.details,
                        status: response.status
                    })
                }
                default: {
                    return reject({
                        type: ApiErrorType.INTERNAL,
                        title: 'Error during json response decoding',
                        detail: 'Internal error',
                        status: HttpReturnCode.INTERNAL
                    })
                }
            }
        }).catch(() => {
            return reject({
                type: ApiErrorType.INTERNAL,
                title: 'Error during json response decoding',
                detail: 'Internal error',
                status: HttpReturnCode.INTERNAL
            })
        })
    })
}