import { PropsWithChildren } from "react";

export interface BoxProps {
    size?: string
    title?: string | React.ReactElement
    subtitle?: string | React.ReactElement
    action?: React.ReactElement
    url?: string
}


export const Box = (props: PropsWithChildren<BoxProps>) => {
    return (
        <div onClick={() => { if (props.url) window.location.assign(props.url) }} className={`${props.size ? props.size : ''} ${props.url ? 'cursor-pointer hover:shadow-lg' : ''} p-4 flex flex-col gap-4 border rounded-lg shadow-md bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-100`}>
            <div>
                <div className='font-bold text-lg text-gray-900 dark:text-gray-200'>
                    {props.title}
                </div>
                <span className='font-normal text-sm italic text-gray-700 dark:text-gray-200'>{props.subtitle}</span>
            </div>
            <div>
                {props.children}
            </div>
            <div className='ml-auto mt-auto'>
                {props.action}
            </div>
        </div>
    )
}
