/**
 * Used HTTP response codes
 */
export enum HttpReturnCode {
    OK = 200,
    CREATED = 201,
    NODATA = 204,
    UNAUTHORIZED = 401,
    CONFLICT = 409,
    NOTFOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    INTERNAL = 500,
}

/**
 * API action to objects in database
 */
export enum ApiAction {
    CREATE = 'POST',
    GET = 'GET',
    DELETE = 'DELETE',
    UPDATE = 'PATCH'
}

/**
 * API error codes, according to API responses
 */
export enum ApiErrorType {
    INTERNAL = 'http://me.com/internal-error',
    DUPLICATE = 'http://me.com/entry-already-exists',
    NOTFOUND = 'http://me.com/reference-not-found',
    MULTIPLEFOUND = 'http://me.com/multiple-reference-found-instead-of-one',
    UNAUTHORIZED = 'http://me.com/not-authorized',
    METHOD_NOT_ALLOWED = 'http://me.com/method-not-allowed'
}

/**
 * API error object.
 * details is mostly "technical detail" (reference ...)
 * message is a user friendly message for user
 */
export interface ApiError {
    type: ApiErrorType;
    title: string;
    status: number;
    detail: string;
}