// React
import { useForm } from "react-hook-form";
import { useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { PostgrestSingleResponse } from '@supabase/postgrest-js'
import { Trans, useTranslation } from 'react-i18next'
// Flowbite & Icons
import { Button, Label, Modal, TextInput } from 'flowbite-react'
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
// Project components & Templates
import { PageTitle, SectionTitle } from '../../../components/Elements/Title'
// Project services & context
import { useToast } from '../../../components/Contexts/ToastContext'
import { WorkspaceProps } from '../Index'
// Services
import { useDb, remove, update } from '../../../services/Db'
import { UserHasRights } from '../../../services/Workspace'
// Config
import { DbRights, DbWorkspaceInterface } from '../../../config/Db'


// Project config

export default function WorkspaceDangerZone(props: WorkspaceProps) {
    const auth = useAuth()
    const toast = useToast()
    const db = useDb('app', auth.user?.access_token || '')
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [modal, setModal] = useState<boolean>(false)
    const [removeConfirmation, setRemoveConfirmation] = useState<string>('')
    const { t } = useTranslation()


    const changeNameSubmitHandler = (data: any) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.WorkspaceReadWrite])) {
            update(db, 'workspace', {
                name: data.name
            }).eq('id', props.workspaceConfig?.workspace?.id).single().then((result: PostgrestSingleResponse<any>) => {
                if (result.error) {
                    toast.fire('failure', 'Workspace name change failed', 3000)
                } else {
                    const wks = result.data as DbWorkspaceInterface
                    toast.fire('success', `Workspace name changed to ${wks.name}`, 2000, () => window.location.reload())
                }
            })
        }
    }

    const removeWorkspaceHandler = () => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.WorkspaceReadWrite])) {
            remove(db, 'workspace').eq('id', props.workspaceConfig?.workspace.id)
                .then((result: PostgrestSingleResponse<any>) => {
                    setModal(false)
                    if (result.error) {
                        toast.fire('failure', 'Workspace removal failed', 3000)
                    } else {
                        toast.fire('success', `Workspace is removed`, 2000, () => window.location.assign('/workspaces'))
                    }
                })
        }
    }

    return (
        <>
            {
                UserHasRights(props.workspaceConfig?.role, [DbRights.WorkspaceReadWrite]) ?
                    <>
                        <PageTitle><span className='text-red-700 dark:text-red-500'>{t('settings.danger.title')}</span></PageTitle>
                        <div className='mt-4 flex flex-col gap-8'>
                            <div>
                                <SectionTitle>{t('settings.danger.change.title')}</SectionTitle>
                                <form onSubmit={handleSubmit((data) => changeNameSubmitHandler(data))} className="flex flex-col gap-4">
                                    <div>
                                        <Label>{t('settings.danger.change.input')}</Label>
                                        <div className='flex flex-row gap-2 item-center'>
                                            <TextInput className='mt-0 flex-grow' defaultValue={props.workspaceConfig?.workspace?.name} {...register("name", { required: true })}></TextInput>
                                            <Button type='submit' className='flex-shrink' color='submit'><CheckIcon className="mr-2 h-5 w-5"></CheckIcon>{t('settings.danger.change.button')}</Button>
                                        </div>
                                        {errors.name && <span className='text-sm text-red-600 dark:text-red-500'>{t('settings.danger.change.error')}</span>}
                                    </div>
                                </form>
                            </div>
                            <div>
                                <SectionTitle>{t('settings.danger.remove.title', { workspace: props.workspaceConfig?.workspace?.name })}</SectionTitle>
                                <div className='text-gray-500 italic dark:text-gray-200'>{t('settings.danger.remove.subtitle')}</div>
                                <div className='flex flex-row justify-end mt-2'>
                                    <Button onClick={() => setModal(true)} color='emergency'><ExclamationTriangleIcon className='h-5 w-5 mr-2' />{t('settings.danger.remove.button')}</Button>
                                </div>
                            </div>
                        </div >
                    </> : <></>

            }
            <Modal show={modal} onClose={() => { setModal(false) }}>
                <Modal.Header><span className='text-red-700 dark:text-red-500'>{t('settings.danger.remove.warning.title')}</span></Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col gap-4'>
                        <div className="text-gray-900 dark:text-gray-100">
                            <Trans i18nKey='settings.danger.remove.warning.message'>
                                You are about to remove {{ workspace: props.workspaceConfig?.workspace.name }} workspace. By clicking on {{ button: t('settings.danger.remove.button') }}, you will definitively remove all the workspace data (chat, setting, billing information, ...).
                                Please enter <code></code> in the text input below to confirm.
                            </Trans>
                        </div>
                        <div>
                            <TextInput autoComplete="off" className='flex-grow' onChange={(event: any) => { setRemoveConfirmation(event.target.value) }}></TextInput>
                        </div>
                        <div className='flex flex-row justify-end'>
                            <Button disabled={removeConfirmation != props.workspaceConfig?.workspace.name} type='submit' color='emergency' onClick={removeWorkspaceHandler}><ExclamationTriangleIcon className='h-5 w-5 mr-2' />{t('settings.danger.remove.button')}</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )

}