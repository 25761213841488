// React
import { useEffect, useState } from 'react'
// Flowbite & icons
import { Button } from 'flowbite-react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
// External packages
import { Navigate } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import { PostgrestSingleResponse } from '@supabase/postgrest-js'
import { useTranslation } from 'react-i18next';
// Services
import { update, useDb } from '../services/Db'
import { deleteStorage, readStorage } from '../services/Storage'
import i18n from '../services/i18n'
// Components
import { PageTitle } from '../components/Elements/Title'
import { Hr } from '../components/Elements/Hr'
import { Page } from '../components/Layout/Page'
// Config
import { RegisterUrl } from '../config/Auth'


export default function Index() {
    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)
    const [target, setTarget] = useState<string | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
        const updateInvite = (invite_ref: string) => {
            update(db, 'invite', {
                status: 'opened',
                to_user_ref: auth.user?.profile.sub
            })
                .eq('ref', invite_ref)
                .in('status', ['sent', 'created', 'opened'])
                .then((result: PostgrestSingleResponse<null>) => {
                    deleteStorage('invite')
                    if (result.error) {
                        setTarget('/')
                    } else {
                        setTarget(`/workspaces`)
                    }
                })
        }

        const acceptInvite = () => {
            const invite: { ref: string } = readStorage('invite')
            if (invite.ref) {
                if (auth.user?.profile) {
                    updateInvite(invite.ref)
                } else {
                    auth.signinSilent().then(() => {
                        if (auth.user?.profile) {
                            updateInvite(invite.ref)
                        }
                    })
                }
            } else {
                if (auth.user?.profile) {
                    setTarget(`/workspaces`)
                }
            }
        }
        acceptInvite()
    }, [auth.user])

    return (
        <>
            {
                target ? <Navigate to={target} />
                    :
                    <Page hcenter={true} vcenter={true}>
                        <div className='flex flex-row items-center justify-center laptop:gap-16 phone:gap-8 flex-wrap'>
                            <div className='max-w-md flex justify-center'>
                                <img src={import.meta.env.VITE_APP_LOGO} className="laptop:w-full phone:w-1/2" alt="Logo" />
                            </div>
                            <div className="text-center max-w-md">
                                <PageTitle subtitle={t("index.subtitle", { project_name: import.meta.env.VITE_PROJECT_NAME })}>{t("index.title", { project_name: import.meta.env.VITE_PROJECT_NAME })}</PageTitle>
                                <div className="mt-10 flex items-center justify-center gap-x-10">
                                    <div className='flex flex-col'>
                                        <Button color='action' data-testid='button-login' onClick={() => { auth.signinRedirect({ redirect_uri: window.location.origin, ui_locales: i18n.language }) }}>
                                            {t("index.login")}
                                            <ArrowRightIcon className="ml-2 h-5 w-5" />
                                        </Button>
                                        <Hr />
                                        <Button color='action' data-testid='button-register' href={RegisterUrl('/', i18n.language)}>
                                            {t("index.register")}
                                            <ArrowRightIcon className="ml-2 h-5 w-5" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Page>
            }
        </>

    )
}
