

export const DbRights: { [key: string]: string } = {
    WorkspaceReadWrite: 'WorkspaceReadWrite',
    SettingsHomeRead: 'SettingsHomeRead',
    SettingsHomeReadWrite: 'SettingsHomeReadWrite',
    SettingsInviteRead: 'SettingsInviteRead',
    SettingsInviteReadWrite: 'SettingsInviteReadWrite',
    SettingsMemberRead: 'SettingsMemberRead',
    SettingsMemberReadWrite: 'SettingsMemberReadWrite',
    SettingsAuditLogRead: 'SettingsAuditLogRead',
    SettingsChatRead: 'SettingsChatRead',
    SettingsChatReadWrite: 'SettingsChatReadWrite',
    SettingsBillingRead: 'SettingsBillingRead',
    SettingsBillingReadWrite: 'SettingsBillingReadWrite',
    SettingsApiKeyRead: 'SettingsApiKeyRead',
    SettingsApiKeyReadWrite: 'SettingsApiKeyReadWrite',
    SettingsRightsRead: 'SettingsRightsRead',
    SettingsRightsReadWrite: 'SettingsRightsReadWrite',

    AppTranscriptRead: 'AppTranscriptRead',
    AppTranscriptReadWrite: 'AppTranscriptReadWrite'
}

export const DbWorkspaceOwner = 'Owner'

export interface DbUserInterface {
    id?: number
    ref?: string
    username?: string
    email?: string
    first_name?: string
    last_name?: string
    created_at?: string
    updated_at?: string
}

export interface DbWorkspaceInterface {
    id?: number
    ref: string
    name: string
}

export interface DbRightInterface {
    id?: number
    name: string
}

export interface DbRoleInterface {
    id?: number
    name: string
    description?: string
    workspace?: DbWorkspaceInterface
    role_right: Array<{ right: DbRightInterface }>
}

export interface DbUserWorkspaceInterface {
    id?: number
    ref: string
    role: DbRoleInterface
    user: DbUserInterface
    workspace: DbWorkspaceInterface
    created_at?: string
    updated_at?: string
}

export interface DbInviteInterface {
    id?: number
    ref: string
    status: string
    to_user_email: string
    role: DbRoleInterface
    user?: DbUserInterface
    created_at?: string
    updated_at?: string
    since?: string
    workspace?: DbWorkspaceInterface
}

export interface DbFileInterface {
    id?: number
    ref: string
    workspace_id: number
    key: string
    name: string
    size?: number
    created_at?: string
    updated_at?: string
}

export interface DbContextMessageInterface {
    id?: number
    ref?: string
    context_id?: number
    role: string
    content: string
    end?: boolean
    hidden?: boolean
}

export interface DbContextInterface {
    id: number
    ref: string
    workspace_id: number
    name: string
    system_prompt: string
    user_prompt: string
    transcript?: string
    created_at?: string
    updated_at?: string
    context_message?: Array<DbContextMessageInterface>
}

