// React
import { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { PostgrestSingleResponse } from '@supabase/postgrest-js';

// Icons
import { ArrowLeftIcon, UserGroupIcon } from '@heroicons/react/24/outline'

// Project components
import { PageTitle } from '../../components/Elements/Title'
import { Card } from '../../components/Elements/Card'
import { Hr } from '../../components/Elements/Hr'

// Project services & context
import { useDb, select, update, insert } from '../../services/Db'

// Project config
import { DbInviteInterface, DbWorkspaceInterface } from '../../config/Db';
import { useToast } from '../../components/Contexts/ToastContext';
import { Page } from '../../components/Layout/Page';
import { Button, Label, Spinner, TextInput } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message"

import { CheckIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';


export default function NewWorkspace() {
    const auth = useAuth()
    const toast = useToast()
    const db = useDb('app', auth.user?.access_token)
    const [pendingInvite, setPendingInvite] = useState<Array<any>>([]);
    const [target, setTarget] = useState<string | undefined>(undefined)
    const { register, handleSubmit, formState: { isSubmitting, isDirty, isValid, errors } } = useForm({ mode: "onChange" });
    const { t } = useTranslation()


    // Update Pending invites handler
    const updatePendingInviteHandler = () => {
        if (auth.user?.profile.sub) {
            select(db, 'invite', 'ref,created_at,workspace(id, ref, name),user!invite_from_user_ref_fkey(username)')
                .eq('to_user_ref', auth.user?.profile.sub)
                .in('status', ['created', 'opened', 'sent'])
                .then((result: PostgrestSingleResponse<any[]>) => {
                    if (result.error) {
                        console.error(result.error.details)
                        setPendingInvite([])
                    }
                    else {
                        setPendingInvite(result.data)
                    }
                })
        }
        else {
            setPendingInvite([])
        }
    }

    // Accept invite handler
    const acceptInviteHandler = (invite: any) => {
        update(db, 'invite', {
            status: 'accepted',
            to_user_ref: auth.user?.profile.sub
        })
            .eq('ref', invite.ref)
            .select('*,workspace(ref,name)')
            .single()
            .then((result: PostgrestSingleResponse<any>) => {
                if (result.error) {
                    console.error(result.error.details)
                    toast.fire('failed', t('newworkspace.invite.failure'), 3000)
                }
                else {
                    const resultData: DbInviteInterface = result.data
                    toast.fire('success', t('newworkspace.invite.success', { workspace: resultData.workspace?.name }), 3000)
                    updatePendingInviteHandler()
                    setTarget(`/workspaces/${resultData.workspace?.ref}/app`)
                }
            })
    }

    // Workspace submit handler
    const workspaceSubmitHandler = (data: any): any => {
        console.log(errors)
        return insert(db, 'workspace', {
            name: data.workspacename
        })
            .single()
            .then((result: PostgrestSingleResponse<any>) => {
                if (result.error) {
                    console.error(result.error.details)
                    toast.fire('failure', t('newworkspace.new.failure', { workspace: data.workspacename }), 3000)
                }
                else {
                    const resultData: DbWorkspaceInterface = result.data
                    toast.fire('success', t('newworkspace.new.success', { workspace: data.workspacename }), 3000)
                    setTarget(`/workspaces/${resultData.ref}/app`)
                }
            })
    }

    // User update
    useEffect(() => {
        updatePendingInviteHandler()
    }, [auth.user])


    return (
        <>
            {
                target ?
                    <Navigate to={target}></Navigate>
                    :
                    <Page hcenter={true} vcenter={true}>
                        <PageTitle>{t('newworkspace.welcome', { project: import.meta.env.VITE_PROJECT_NAME })}</PageTitle>
                        <div className='mt-4'>
                            <Card maxwidth='xl' title={t('newworkspace.title')} subtitle={t('newworkspace.subtitle')}>
                                {
                                    pendingInvite.length > 0 ?
                                        pendingInvite.map((invite, index) => (
                                            <div key={index} onClick={() => acceptInviteHandler(invite)} className='rounded-lg gap-4 p-6 border border-gray-300 dark:border-gray-100 flex items-center bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 cursor-pointer'>
                                                <UserGroupIcon className='h-6 w-6 text-gray-900 dark:text-gray-200' title='join workspace {invite.workspace.name}'></UserGroupIcon>
                                                <div>
                                                    <div className='text-gray-900 dark:text-gray-200'>{t('newworkspace.invite.title', { workspace: invite.workspace.name })}</div>
                                                    <div className='text-gray-700 dark:text-gray-300 text-xs'>{t('newworkspace.invite.subtitle', { user: invite.user.username })}</div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <></>
                                }
                                {
                                    pendingInvite.length > 0 ?
                                        <>
                                            <Hr label='or' />
                                            <div className='font-bold text-lg'>{t('newworkspace.new.title')}</div>
                                        </>
                                        :
                                        <></>
                                }
                                <form onSubmit={handleSubmit((data) => workspaceSubmitHandler(data))} className="flex flex-col gap-4">
                                    <div>
                                        <Label htmlFor='workspacename' value={t('newworkspace.new.input')} />
                                        <TextInput placeholder={t('newworkspace.new.placeholder')} {...register("workspacename", { required: true, minLength: { value: 4, message: t('newworkspace.new.errors.minlength') }, maxLength: { value: 32, message: t('newworkspace.new.errors.maxlength') } })}></TextInput>
                                        <ErrorMessage as={<span className='text-xs font-mono text-red-700' />} errors={errors} name="workspacename" />
                                    </div>
                                    <Button id='new-workspace-submit' color="submit" type="submit" disabled={!isDirty || !isValid}>
                                        {t('newworkspace.new.button')} {isSubmitting ? <Spinner className="ml-2" color="info" aria-label="Submitting spinner" />
                                            : <CheckIcon className="ml-2 h-5 w-5" />}
                                    </Button>
                                </form>
                            </Card>
                        </div>
                        <div className="mt-10 flex items-center justify-center gap-x-10">
                            <Button color='action' as={Link} to='/'>
                                <ArrowLeftIcon className="h-5 w-5 mr-2" />
                                {t('general.backtohome')}
                            </Button>
                        </div>
                    </Page >
            }
        </>
    )
}