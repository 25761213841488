// React
import { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from "react-oidc-context";
import { Flag } from "../Elements/Flag"
// Flowbite & icons
import { Navbar, Dropdown, Avatar, DarkThemeToggle, Button } from 'flowbite-react'
import { UserCircleIcon } from '@heroicons/react/24/outline'
// External packages
import { IdTokenClaims } from 'oidc-client-ts'
import { PostgrestSingleResponse } from '@supabase/postgrest-js'
// Services
import { useDb, select, update, insert } from '../../services/Db'
import { DbUserInterface } from '../../config/Db'
// Components
import { getAvatarImage } from '../Elements/AvatarImage'
import { useTranslation } from 'react-i18next';
// Config
import { languages } from '../../services/i18n'


export const Nav = (): any => {
    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)
    const [userImg, setUserImg] = useState<string>('')
    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language.slice(0, 2)

    const StoreUserInfoInDb = (profile: IdTokenClaims | undefined, token: undefined | string) => {
        if (!profile || !token) return

        select(db, 'user', 'ref,username,updated_at')
            .eq('username', profile.preferred_username)
            .maybeSingle()
            .then((result: PostgrestSingleResponse<any>) => {
                if (result.status == 403) {
                    console.error(result.error?.details)
                    auth.signinRedirect({
                        redirect_uri: window.location.href,
                        ui_locales: currentLanguage
                    })
                }
                else if (!result.data) {
                    insert(db, 'user', {
                        email: profile.email,
                        first_name: profile.given_name,
                        last_name: profile.family_name,
                        username: profile.preferred_username,
                        ref: profile.sub
                    }).then(() => {

                    })
                }
                else {
                    const data = result.data as DbUserInterface
                    update(db, 'user', {
                        email: profile.email,
                        first_name: profile.given_name,
                        last_name: profile.family_name
                    }).eq('ref', data.ref)
                        .then(() => {

                        })
                }
            })
    }

    useEffect(() => {
        if (auth.user) {
            setUserImg(getAvatarImage(auth.user?.profile.given_name, auth.user?.profile.family_name, 64))
            StoreUserInfoInDb(auth.user?.profile, auth.user?.access_token)
        } else {
            auth.signinSilent()
        }
    }, [auth.user])

    useEffect(() => {
        return auth.events.addAccessTokenExpiring(() => {
            auth.startSilentRenew()
        })
    }, [auth.events]);

    return (
        <Navbar className='bg-white dark:border-gray-500 border-b h-14 fixed w-full z-20 top-0 start-0'>
            <div className='flex items-center'>
                <Navbar.Brand as={Link} to="/">
                    <img src={import.meta.env.VITE_APP_LOGO} className="h-8 w-8" alt="Logo" />
                </Navbar.Brand>
            </div>
            <div className='italic text-gray-900 dark:text-gray-200 phone:hidden tablet:block'>{import.meta.env.VITE_PROJECT_NAME} by {import.meta.env.VITE_APP_ORGANIZATION} ©</div>
            <div className='flex'>
                <Dropdown
                    inline
                    arrowIcon={false}
                    label={
                        <Flag className='w-6 h-4 mr-2 ml-2 rounded' country={languages[currentLanguage].flag} />
                    }
                    className='mr-2'
                >
                    {
                        Object.keys(languages).map((lang, index) => (
                            lang != currentLanguage ?
                                <Dropdown.Item onClick={() => {
                                    i18n.changeLanguage(lang)
                                }} key={index}><Flag className='w-6 mr-2 ml-2 rounded' country={languages[lang].flag} />{languages[lang].name}</Dropdown.Item>
                                :
                                <Fragment key={index}></Fragment>
                        ))
                    }
                </Dropdown>
                {
                    auth.isAuthenticated ?
                        <>

                            <DarkThemeToggle className='text-gray-900 dark:text-white'></DarkThemeToggle>
                            <div className="flex" data-testid='button-user'>
                                <Dropdown
                                    arrowIcon={false}
                                    inline
                                    label={
                                        <Avatar className='mr-2 ml-2' size={'sm'} alt="User settings" img={userImg} rounded />
                                    }
                                    className='ml-2'
                                >
                                    <Dropdown.Header>
                                        <span id='nav-user-name' className="block text-sm font-semibold truncate text-gray-900 dark:text-white">{auth.user?.profile.name}</span>
                                        <span id='nav-user-email' className="block text-xs text-gray-500 truncate dark:text-gray-400">{auth.user?.profile.email}</span>
                                    </Dropdown.Header>
                                    <Dropdown.Item as={Link} to='/workspace'>{t('nav.createwks')}</Dropdown.Item>
                                    <Dropdown.Item as={Link} to='/user-settings'>{t('nav.settings')}</Dropdown.Item>
                                    <Dropdown.Item as={Link} target="_blank" to={import.meta.env.VITE_DOC_URL}>{t('nav.documentation')}</Dropdown.Item>
                                    <Dropdown.Item id='nav-logout-button' onClick={() => auth.signoutRedirect({
                                        redirectMethod: 'replace',
                                        redirectTarget: 'top',
                                        post_logout_redirect_uri: `${window.location.origin}`
                                    })}>
                                        {t('nav.logout')}
                                    </Dropdown.Item>
                                </Dropdown>
                            </div>
                        </>
                        :
                        <>
                            <DarkThemeToggle></DarkThemeToggle>
                            <Button data-testid='button-user' className='mr-2' color='transparent' size='sm' name='nav-login' onClick={() => { auth.signinRedirect({ redirect_uri: window.location.origin, ui_locales: currentLanguage }) }}>
                                <UserCircleIcon className="h-6 w-6" />
                            </Button>
                        </>
                }
            </div>
        </Navbar>
    )
}