import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';

import fr from '../locales/fr.json'
import en from '../locales/en.json'
import es from '../locales/es.json'
import de from '../locales/de.json'
import it from '../locales/it.json'


i18n.use(LanguageDetector).use(initReactI18next).init({
    detection: {
        order: ['localStorage', 'navigator']
    },
    resources: {
        en: {
            translation: en,
        },
        fr: {
            translation: fr,
        },
        es: {
            translation: es,
        },
        de: {
            translation: de,
        },
        it: {
            translation: it,
        },
    },
})

i18n.on('languageChanged', (lng) => (document.documentElement.lang = lng))

export interface languagesConfig {
    [key: string]: {
        flag: string
        name: string
    }
}

export const languages: languagesConfig = {
    en: {
        flag: 'GB',
        name: 'English'
    },
    fr: {
        flag: 'FR',
        name: 'Français'
    },
    de: {
        flag: 'DE',
        name: 'Deutsche'
    },
    es: {
        flag: 'ES',
        name: 'Español'
    },
    it: {
        flag: 'IT',
        name: 'Italian'
    }
}

Moment.globalMoment = moment;
Moment.globalLocal = true;

export default i18n;
