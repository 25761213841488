// React
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import { useTranslation } from 'react-i18next'
// Flowbite & icons
import { Dropdown, Badge } from 'flowbite-react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
// Services
import { useDb } from '../../services/Db'
// Components
import { getWorkspace, getWorkspaces, UserHasRights } from '../../services/Workspace'
// Config
import { DbUserWorkspaceInterface } from '../../config/Db'


export interface SidebarMenuItem {
    label: string
    name: string
    icon: React.ReactElement
    rights: Array<string>
    action: (item: SidebarMenuItem) => void
}

export interface SidebarProps {
    item?: string // current page in the space
    selector?: boolean // Workspace selector is displayed or not
    items: Array<SidebarMenuItem> // List of available pages in the space
    spaceSwitch: SidebarMenuItem // Space switch at the bottom of the sidebar
    opened: boolean
}

export const Sidebar = (props: SidebarProps): any => {

    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)

    const { workspace_ref } = useParams();
    const [workspaceConfig, setWorkspaceConfig] = useState<DbUserWorkspaceInterface | null>(null)
    const [workspacesConfig, setWorkspacesConfig] = useState<Array<DbUserWorkspaceInterface> | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
        if (auth.user?.access_token && workspace_ref) {
            getWorkspaces(db, auth.user?.profile.sub)
                .then((wks: Array<DbUserWorkspaceInterface>) => {
                    setWorkspacesConfig(wks)
                })
            getWorkspace(db, auth.user?.profile.sub, workspace_ref)
                .then((wk: DbUserWorkspaceInterface) => {
                    setWorkspaceConfig(wk)
                })
        }
    }, [auth.user, workspace_ref])

    return (
        <>
            {
                workspaceConfig ?
                    workspaceConfig.workspace ?
                        <div className='flex flex-col h-full dark:border-gray-500 border-r relative duration-300'>
                            {props.selector ?
                                <div className={`${props.opened ? "" : "hidden"} h-10 flex flex-col justify-center`}>
                                    <Dropdown
                                        hidden={true}
                                        label=""
                                        renderTrigger={
                                            () =>
                                                <button id='select-workspaces' className='w-full font-bold text-gray-900 dark:text-gray-200 truncate'>
                                                    {workspaceConfig?.workspace?.name}
                                                    <ChevronDownIcon className='ml-2 h-4 w-4 inline'></ChevronDownIcon>
                                                </button>
                                        }
                                    >
                                        <Dropdown.Header>
                                            <span id='nav-user-name' className="block text-sm font-semibold truncate text-gray-900 dark:text-white">{auth.user?.profile.name}</span>
                                            <span id='nav-user-email' className="block text-xs text-gray-500 truncate dark:text-gray-400">{auth.user?.profile.email}</span>
                                        </Dropdown.Header>
                                        {
                                            workspacesConfig?.map((wks: DbUserWorkspaceInterface) => (
                                                <div key={wks.ref}>
                                                    {
                                                        workspaceConfig.ref != wks.ref ?
                                                            <Dropdown.Item as={Link} to={`/workspaces/${wks.workspace?.ref}/app`}>
                                                                <Badge className='justify-center mr-2' color="blue">{t(wks.role.name)}</Badge>
                                                                {wks.workspace?.name}
                                                            </Dropdown.Item> :
                                                            <></>
                                                    }
                                                </div>
                                            ))
                                        }
                                        <Dropdown.Divider></Dropdown.Divider>
                                        <Dropdown.Item key='new' as={Link} to='/workspace'><div className='mx-auto font-bold'>{t('sidebar.createwks')}</div></Dropdown.Item>
                                    </Dropdown>
                                </div> :
                                <></>
                            }
                            <div className={`flex-grow overflow-y-scroll ${props.opened ? "border-t" : ""}`}>
                                <ul>
                                    {
                                        props.items.map(p => (
                                            <React.Fragment key={p.name}>
                                                {
                                                    UserHasRights(workspaceConfig.role, p.rights) ?
                                                        <li onClick={() => { p.action(p) }} className={`flex rounded-md p-2 cursor-pointer text-gray-900 dark:text-gray-100 ${props.item == p.name ? "bg-gray-100 dark:bg-gray-700" : ""} hover:bg-gray-100 dark:hover:bg-gray-700 text-sm items-center gap-x-4 mt-2 bg-light-white`}>
                                                            <div>{p.icon}</div>
                                                            <div className={`${!props.opened && "hidden"} origin-left duration-200 overflow-x-hidden`}>{t(p.label)}</div>
                                                        </li>
                                                        : <></>
                                                }
                                            </React.Fragment>
                                        )
                                        )
                                    }
                                </ul>
                            </div>
                            <div className='border-t'>
                                <ul>
                                    <li key={props.spaceSwitch.name} onClick={() => { props.spaceSwitch.action(props.spaceSwitch) }} className="flex rounded-md p-2 cursor-pointer text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 text-sm items-center gap-x-4 mt-2 bg-light-white">
                                        {props.spaceSwitch.icon}
                                        <span className={`${!props.opened && "hidden"} origin-left duration-200`}>{t(props.spaceSwitch.label)}</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        :
                        < Navigate to={`/workspaces}`}></Navigate>
                    :
                    <></>

            }
        </>


    )
}