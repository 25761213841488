import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { FileInput, Label, Spinner } from "flowbite-react";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

export interface FileDropProps {
    width: string
    text?: string
    fileHandler: (file: File) => Promise<void>
}


export function FileDrop(props: FileDropProps) {
    const { t } = useTranslation()
    const [spinner, setSpinner] = useState<boolean>(false)

    const fileChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target && event.target.files && event.target.files.length == 1) {
            setSpinner(true)
            props.fileHandler(event.target.files[0]).then(() => setSpinner(false)).catch(() => {
                setSpinner(false)
            })
        }
    }

    const fileDropHandler = (files: FileList) => {
        if (files.length == 1) {
            setSpinner(true)
            props.fileHandler(files[0]).then(() => {
                setSpinner(false)
            }).catch(() => {
                setSpinner(false)
            })
        }
    };

    return (
        <div className={`${props.width} flex items-center justify-center`}>
            <Label
                htmlFor="dropzone-file"
                className="flex p-2 h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                onDrop={(event) => { event.preventDefault(); fileDropHandler(event.dataTransfer?.files) }}
                onDragOver={(event) => event.preventDefault()}
            >
                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                    {
                        spinner ? <Spinner size='md' className="ml-2" color="info" aria-label="File selection spinner" /> : <CloudArrowUpIcon className="h-8 w-8"></CloudArrowUpIcon>

                    }
                    <div className="mb-2 text-center text-sm text-gray-900 dark:text-gray-100">
                        {t(props.text || 'general.upload')}
                    </div>
                </div>
                <FileInput accept={`audio/*, text/*`} multiple={false} id="dropzone-file" className="hidden" onChange={fileChangeHandler} />
            </Label >
        </div >
    );
}