
import { ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getWorkspace } from '../../../services/Workspace';
import { useAuth } from 'react-oidc-context';
import { Nav } from '../../../components/Layout/Nav';
import { Button } from 'flowbite-react';
import { useDb } from '../../../services/Db';
import { useTranslation } from 'react-i18next';


export default function WorkspaceLoginSuccess() {
    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)


    const { workspace_ref } = useParams();
    const [config, setConfig] = useState<any | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
        if (auth.user?.access_token && workspace_ref) {
            getWorkspace(db, auth.user?.profile.sub, workspace_ref)
                .then((wk) => {
                    setConfig(wk)
                })
        }
    }, [auth.user, workspace_ref])


    return (
        <>
            <Nav></Nav>
            <div className='flex flex-row mt-14 h-[calc(100vh-56px)] w-full'>
                <div className='flex flex-col gap-8 justify-center mx-auto'>
                    <div className='flex flex-row gap-4 items-center'>
                        <div><CheckCircleIcon className='h-16 w-16 text-green-700 dark:text-green-400'></CheckCircleIcon></div>
                        <div>
                            <p className='text-xl text-gray-900 dark:text-gray-100'>{t('loginsuccess.connected', { workspace: config?.workspace.name, user: config?.user.username })}</p>
                            <p className='text-xl text-gray-900 dark:text-gray-100'>{t('loginsuccess.ready')}</p>
                        </div>
                    </div>
                    <div className='flex flex-row gap-4 justify-center'>
                        <Button color='action' className='max-w-xl' as={Link} to={`/workspaces/${workspace_ref}`}>
                            {t('loginsuccess.go')}
                            <ArrowRightIcon className="h-5 w-5 ml-2" />
                        </Button>
                    </div>
                </div>
            </div>
        </>

    )
}