// React
import { useParams } from 'react-router-dom'
import { writeStorage } from '../services/Storage'
import { RegisterUrl } from '../config/Auth'
import { useAuth } from 'react-oidc-context'
import i18n from '../services/i18n'

// Icons
// Project components & Templates
// Project services & context


export default function Invite() {
    const { invite_ref } = useParams();
    const auth = useAuth()
    const registerUrl = RegisterUrl('/', i18n.language)

    writeStorage('invite', { ref: invite_ref })
    if (auth.isAuthenticated) {
        window.location.assign('/')
    } else {
        window.location.assign(registerUrl)
    }

    return (
        <></>
    )

}