import { useAuth } from 'react-oidc-context'
import { PageTitle } from '../../../components/Elements/Title'
import { WorkspaceProps } from '../Index'
import { UserHasRights } from '../../../services/Workspace'
import { DbRights } from '../../../config/Db'
import { select, useDb } from '../../../services/Db'
import { Datatable, TableData } from '../../../components/Elements/DataTable'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Project services & context
// Project config


export default function WorkspaceSettingsAuditLogs(props: WorkspaceProps) {
    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)
    const { t } = useTranslation()


    const hydrate = async (last: number): Promise<Array<TableData>> => {
        if (props.workspaceConfig.workspace) {
            // create query with select
            let query = select(db, 'audit_log', 'id,object,action,description,created_at,username,workspace')
            // then filter it
            query = query.or(`and(username.eq.${auth.user?.profile.preferred_username},workspace.is.null),workspace.eq.${props.workspaceConfig.workspace.name}`)
            // take care of pagination with 'last' (infinite scroll)
            query = query.gt('id', last)
            // limit number
            query.limit(20)
            // order
            query.order('id', { ascending: true })
            // execute query
            const result = await query
            if (result.error) {
                console.error(result.error)
                return Promise.resolve([])
            } else {
                return Promise.resolve(result.data.map((value: any) => {
                    return {
                        id: value.id,
                        ...value
                    }
                }))
            }
        } else {
            return Promise.resolve([])
        }
    }

    return (
        <>
            {
                UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsAuditLogRead]) ?
                    <>
                        <PageTitle>{t('settings.logs.title')}</PageTitle>
                        <div className='mt-4 h-[calc(100%-56px)]'>
                            <Datatable textsize='text-xs' hydrate={hydrate} height='h-[calc(100%-20px)]' headers={[
                                { label: t('settings.logs.date'), path: 'created_at', format: 'date', size: 'w-[10%]' },
                                { label: t('settings.logs.workspace'), path: 'workspace', size: 'w-[15%]' },
                                { label: t('settings.logs.object'), path: 'object', format: 'translated', size: 'w-[10%]' },
                                { label: t('settings.logs.action'), path: 'action', format: 'translated', size: 'w-[10%]' },
                                { label: t('settings.logs.description'), path: 'description', size: 'w-[40%]' },
                                { label: t('settings.logs.user'), path: 'username', size: 'w-[15%]' },
                            ]} />
                        </div>
                    </>
                    : props.workspaceConfig?.role ? <Navigate to="/denied"></Navigate> : <></>
            }
        </>
    )
}