// React
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { Link } from 'react-router-dom'
import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
// Flowbite & Icon 
import { Button, Label, Spinner, TextInput } from 'flowbite-react'
import { CheckIcon, ArrowLeftIcon, CheckCircleIcon, ComputerDesktopIcon, KeyIcon, QrCodeIcon, XMarkIcon } from '@heroicons/react/24/outline'
// Components
import { Card } from '../components/Elements/Card'
import { useToast } from '../components/Contexts/ToastContext'
import { Page } from '../components/Layout/Page'
import { PageTitle } from '../components/Elements/Title'
// Services
import i18n from '../services/i18n'
// Config
import { oidcConfig } from '../config/Auth'


export default function Settings() {
    const auth = useAuth()
    const toast = useToast()
    const [mfaCredentials, setMfaCredentials] = useState<Array<any>>([])
    const [sessions, setSessions] = useState<Array<any>>([])
    const { register, setValue, handleSubmit, formState: { isSubmitting, isDirty, isValid, errors } } = useForm({ mode: "onChange" });
    const { t } = useTranslation()

    const getUserProfile = (): Promise<any> => {
        const config = oidcConfig(window.location.origin)
        if (auth.user?.access_token) {
            return fetch(`${config.authority}/account`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.user?.access_token}`,
                }
            }).then((value: Response) => {
                return value.json()
            })
        }
        else {
            return Promise.reject()
        }
    }
    const setUserProfile = (profile: any): Promise<any> => {
        const config = oidcConfig(window.location.origin)

        return fetch(`${config.authority}/account`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user?.access_token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                firstName: profile.firstName,
                lastName: profile.lastName,
                email: profile.email,
            })
        }).then((value: Response) => {
            if (value.ok) {
                return profile
            }
            else {
                return value.json()
            }
        }).catch((reason: any) => {
            return reason
        });
    }

    const configureMFA = () => {
        auth.signinRedirect({
            redirect_uri: window.location.href, extraQueryParams: {
                kc_action: "CONFIGURE_TOTP",
            },
            ui_locales: i18n.language
        })
    }

    const changePassword = () => {
        auth.signinRedirect({
            redirect_uri: window.location.href, extraQueryParams: {
                kc_action: "UPDATE_PASSWORD"
            },
            ui_locales: i18n.language
        })
    }

    const disableMFA = (id: string) => {
        const config = oidcConfig(window.location.origin)
        return fetch(`${config.authority}/account/credentials/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user?.access_token}`,
            }
        }).then(() => getAccountMFA().then((values: Array<any>) => {
            if (values.length == 0) {
                setMfaCredentials([])
            } else {
                setMfaCredentials(values[0].userCredentialMetadatas)
            }
        }))
    }

    const getAccountMFA = (): Promise<any> => {
        const config = oidcConfig(window.location.origin)
        if (auth.user?.access_token) {
            return fetch(`${config.authority}/account/credentials?type=otp&user-credentials=true`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.user?.access_token}`,
                }
            }).then((value: Response) => {
                return value.json()
            })
        }
        else {
            return Promise.reject()
        }
    }

    const getSessions = () => {
        const config = oidcConfig(window.location.origin)
        if (auth.user?.access_token) {
            return fetch(`${config.authority}/account/sessions`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.user?.access_token}`,
                }
            }).then((value: Response) => {
                return value.json()
            })
        }
        else {
            return Promise.reject()
        }
    }

    const dropSession = (id: string) => {
        const config = oidcConfig(window.location.origin)
        if (auth.user?.access_token) {
            return fetch(`${config.authority}/account/sessions/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.user?.access_token}`,
                }
            }).then(() => {
            })
        }
        else {
            return Promise.reject()
        }
    }


    /*************************************************************************
    * User settings forms helpers
    *************************************************************************/
    const userSettingsSubmit = (data: any): Promise<any> => {
        return setUserProfile(data).then((result) => {
            toast.fire('info', t('usersettings.account.saved', { user: result.username }), 3000, () => {
                auth.removeUser().then(() => auth.signinSilent().then(() => {
                    toast.fire('success', t('usersettings.account.updated'), 2000)
                }))
            })
            return result
        })
    }

    useEffect(() => {
        if (auth.user?.access_token) {
            getUserProfile().then((prfl: any) => {
                setValue('email', prfl.email)
                setValue('firstName', prfl.firstName)
                setValue('lastName', prfl.lastName)
            })
            getAccountMFA().then((values: any) => {
                if (values.length == 0) {
                    setMfaCredentials([])
                } else {
                    setMfaCredentials(values[0].userCredentialMetadatas)
                }
            })
            getSessions().then((values: any) => {
                setSessions(values)
            })
        }
    }, [auth.user])

    return (
        <Page>
            <PageTitle>{t('usersettings.title')}</PageTitle>
            <div className='flex flex-row flex-wrap gap-x-4 gap-y-4 w-full justify-center items-start mt-4'>
                <Card maxwidth='lg' fullwidth={true} title={t('usersettings.account.title')} collapsible={true}>
                    <div className="flow-root">
                        <ul className="divide-y divide-gray-200 dark:divide-gray-500">
                            <li key='info' className="py-3 phone:py-4">
                                <form onSubmit={handleSubmit((data) => userSettingsSubmit(data))} className="flex flex-col gap-4">
                                    <div>
                                        <Label htmlFor='' value={t('usersettings.account.email')} />
                                        <TextInput type="email" {...register("email", {
                                            required: 'Email is required', pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Email field needs an email format"
                                            }
                                        })}></TextInput>
                                        <ErrorMessage as={<span className='text-xs font-mono text-red-700 dark:text-red-500' />} errors={errors} name="email" />
                                    </div>
                                    <div>
                                        <Label htmlFor='' value={t('usersettings.account.first_name')} />
                                        <TextInput  {...register("firstName", { required: 'First name is required' })}></TextInput>
                                        <ErrorMessage as={<span className='text-xs font-mono text-red-700 dark:text-red-500' />} errors={errors} name="firstName" />
                                    </div>
                                    <div>
                                        <Label htmlFor='' value={t('usersettings.account.last_name')} />
                                        <TextInput {...register("lastName", { required: 'Last name is required' })}></TextInput>
                                        <ErrorMessage as={<span className='text-xs font-mono text-red-700 dark:text-red-500' />} errors={errors} name="lastName" />
                                    </div>
                                    <Button id='user-settings-submit' color="submit" type="submit" disabled={!isDirty || !isValid}>
                                        {t('usersettings.account.button')}
                                        {isSubmitting ? <Spinner className="ml-2" color="info" aria-label="Submitting spinner" />
                                            : <CheckIcon className="ml-2 h-5 w-5" />}
                                    </Button>
                                </form>
                            </li>
                            <li key='passwd' className="py-3 phone:py-4">
                                <Button color='action' className="mr-auto" onClick={() => changePassword()}>{t('usersettings.password.button')}<KeyIcon className="ml-2 h-5 w-5" /></Button>

                            </li>
                        </ul>
                    </div>

                </Card>
                <Card maxwidth='lg' fullwidth={true} title={t('usersettings.mfa.title')} collapsible={true}>
                    <div className="flow-root">
                        <ul className="divide-y divide-gray-200 dark:divide-gray-500">
                            <>
                                {mfaCredentials.length > 0 ?
                                    mfaCredentials.map((m, index) => (
                                        <li key={index} className="py-3 phone:py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="shrink-0">
                                                    <CheckCircleIcon className="h-6 text-green-600 dark:text-green-400"></CheckCircleIcon>
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">{t('usersettings.mfa.configured')}</p>
                                                    <p className="text-sm text-gray-500 dark:text-gray-300">{m.credential.userLabel}</p>
                                                </div>
                                                <div className="inline-flex items-center">
                                                    <Button color='action' onClick={() => disableMFA(m.credential.id)?.then(() => { })}>{t('usersettings.mfa.disable')}<QrCodeIcon className="ml-2 h-5 w-5" /></Button>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                    : <li className="py-3 phone:py-4">
                                        <div className="flex items-center space-x-4">
                                            <div className="shrink-0">
                                                <XMarkIcon className="h-6 text-red-600 dark:text-red-400"></XMarkIcon>
                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">{t('usersettings.mfa.no')}</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-300">{t('usersettings.mfa.click')}</p>
                                            </div>
                                            <div className="inline-flex items-center">
                                                <Button color='action' className="ml-auto" onClick={() => configureMFA()}>{t('usersettings.mfa.configure')}<QrCodeIcon className="ml-2 h-5 w-5" /></Button>
                                            </div>
                                        </div>
                                    </li>
                                }
                                {
                                    sessions.map((s, index) => (
                                        <li key={index} className="py-3 phone:py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="shrink-0">
                                                    <ComputerDesktopIcon className="h-6 text-gray-900 dark:text-gray-100"></ComputerDesktopIcon>
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">{s.current ? t('usersettings.sessions.current') : t('usersettings.sessions.session')}: {s.browser}</p>
                                                    <p className="text-xs text-gray-500 dark:text-gray-300">{s.ipAddress}</p>
                                                </div>
                                                <div className="inline-flex items-center">
                                                    {
                                                        !s.current ?
                                                            <Button color='action' onClick={() => {
                                                                dropSession(s.id).then(
                                                                    () => {
                                                                        getSessions().then(
                                                                            (values: any) => { setSessions(values) })
                                                                    })
                                                            }}>{t('usersettings.sessions.drop')}<XMarkIcon className="ml-2 h-5 w-5" /></Button> : <></>}
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                            </>
                        </ul>
                    </div>
                </Card>
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-10">
                <Button color='action' as={Link} to='/'>
                    <ArrowLeftIcon className="h-5 w-5 mr-2" />
                    {t('general.backtohome')}
                </Button>
            </div>
        </Page>
    )
}
