import { useAuth } from "react-oidc-context"
//import { useToast } from "../components/Contexts/ToastContext"
import { PageTitle } from "../components/Elements/Title"
import { Page } from "../components/Layout/Page"
import { useEffect, useState } from "react"
import { DbUserWorkspaceInterface } from "../config/Db"
import { getWorkspaces } from "../services/Workspace"
import { BoxSet } from "../components/Layout/BoxSet"
import { Box } from "../components/Elements/Box"
import { Badge } from "flowbite-react"
import { Datatable, TableData } from "../components/Elements/DataTable"
import { select, useDb } from "../services/Db"

export default function Dashboard() {
    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)
    //const toast = useToast()

    const [workspaces, setWorkspaces] = useState<Array<DbUserWorkspaceInterface>>([])
    const [workspacesIn, setWorkspacesIn] = useState<string>('')

    useEffect(() => {
        if (auth.user?.access_token) {
            getWorkspaces(db, auth.user?.profile.sub)
                .then((wks: Array<DbUserWorkspaceInterface>) => {
                    setWorkspaces(wks)
                    const wksnames = wks.map((w) => {
                        return w.workspace.name
                    })
                    const str = wksnames.join(',')
                    setWorkspacesIn(str)
                })
        }
    }, [auth.user])

    const hydrate = async (last: number): Promise<Array<TableData>> => {
        // create query with select
        let query = select(db, 'audit_log', 'id,object,action,description,created_at,username,workspace')
        // then filter it
        query = query.or(`username.eq.${auth.user?.profile.preferred_username},workspace.is.null,workspace.in.(${workspacesIn})`)
        // take care of pagination with 'last' (infinite scroll)
        query = query.gt('id', last)
        // limit number
        query.limit(20)
        // order
        query.order('id', { ascending: true })
        // execute query
        const result = await query
        if (result.error) {
            console.error(result.error)
            return Promise.resolve([])
        } else {
            return Promise.resolve(result.data.map((value: any) => {
                return {
                    id: value.id,
                    ...value
                }
            }))
        }
    }

    return (
        <Page>
            <PageTitle>Dashboard</PageTitle>
            <div className='flex flex-row gap-4 mt-4 h-full'>
                <div className="max-w-2xl">
                    <BoxSet title="Workspaces">
                        <>
                            {
                                workspaces?.map((workspace, index) => (
                                    <Box url={`/workspaces/${workspace.workspace.ref}`} key={index} title={workspace.workspace.name} size='min-w-40'>
                                        <div className='flex flex-col'>
                                            <div className="flex flex-row">
                                                <Badge color="blue">{workspace.role.name}</Badge>
                                            </div>
                                        </div>
                                    </Box>
                                ))
                            }
                        </>
                    </BoxSet>
                </div>
                <div className="flex-grow flex flex-col">
                    <div className="flex-1 h-1/2">
                        <Datatable title='Audit logs' textsize='text-xs' hydrate={hydrate} height='h-1/2' headers={[
                            { label: 'Date', path: 'created_at', format: 'date', size: 'w-[10%]' },
                            { label: 'Workspace', path: 'workspace', size: 'w-[15%]' },
                            { label: 'Object', path: 'object', format: 'translated', size: 'w-[10%]' },
                            { label: 'Action', path: 'action', format: 'translated', size: 'w-[10%]' },
                            { label: 'Description', path: 'description', size: 'w-[40%]' },
                            { label: 'User', path: 'username', size: 'w-[15%]' },
                        ]} />
                    </div>
                </div>
            </div>
        </Page>
    )
}