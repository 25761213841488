import { createContext, useContext, useState } from 'react';
import { Toast } from 'flowbite-react';
import { CheckIcon, XMarkIcon, ChatBubbleOvalLeftIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';

export interface ToastContextProps {
    children?: any
}

export interface ToastContextInterface {
    fire: (status_: string, message_: string, duration: number, callback?: () => void) => void
}

export const ToastContext = createContext({} as ToastContextInterface)

export const ToastContextProvider = (props: ToastContextProps) => {
    const [message, setMessage] = useState<string | undefined>(undefined)
    const [status, setStatus] = useState<string>('')
    const [visible, setVisible] = useState<boolean>(false)

    const colors: any = {
        success: 'bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200',
        failure: 'bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200',
        info: 'bg-sky-100 text-sky-500 dark:bg-sky-600 dark:text-sky-200',
        right: 'bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200'
    }

    const addToast = (status_: string, message_: string, duration: number, callback?: () => void) => {
        setMessage(message_)
        setStatus(status_)
        setVisible(true)
        setTimeout(() => {
            setMessage(undefined)
            setStatus('')
            setVisible(false)
            if (callback) {
                callback()
            }
        }, duration)
    }

    return (
        <ToastContext.Provider value={{ fire: addToast }}>
            {props.children}
            {
                visible ?
                    <div className='fixed top-1 left-1/2 -translate-x-1/2 z-20'>
                        <Toast id={`${status}-toast`} className='p-2 max-w-full'>
                            <div className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${colors[status]}`}>
                                {
                                    status == 'success' ?
                                        <CheckIcon className="h-5 w-5" />
                                        : status == 'failure' ?
                                            <XMarkIcon className="h-5 w-5" />
                                            : status == 'right' ?
                                                <ShieldCheckIcon className="h-5 w-5"></ShieldCheckIcon>
                                                :
                                                <ChatBubbleOvalLeftIcon className="h-5 w-5" />
                                }
                            </div>
                            <div id={`toast-${status}`} className="ml-3 text-sm font-normal text-gray-700 dark:text-gray-200">{message}</div>
                        </Toast>
                    </div>
                    :
                    <></>
            }
        </ToastContext.Provider>
    );
};


export const useToast = (): ToastContextInterface => {
    return useContext(ToastContext)
}


