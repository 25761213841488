// React
import { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { useParams } from 'react-router-dom'

// Flowbite & Icons
import { ChatBubbleLeftRightIcon, CommandLineIcon, DocumentTextIcon, ExclamationTriangleIcon, ShieldExclamationIcon, UsersIcon } from '@heroicons/react/24/outline'

// Project components & Templates
import { PageWithSidebar } from '../../../components/Layout/Page'
import { Sidebar, SidebarMenuItem } from '../../../components/Layout/Sidebar'
import { getWorkspace } from '../../../services/Workspace'
import WorkspaceChat from './Chat'
import WorkspaceMembers from './Members'
import WorkspaceSettingsAuditLogs from './Logs'
import WorkspaceDangerZone from './Danger'
import WorkspaceRights from './Rights'

// Services
import { useDb } from '../../../services/Db'
import { readStorage, writeStorage } from '../../../services/Storage'

// Config
import { DbRights } from '../../../config/Db'


export default function WorkspaceSettings() {
    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)

    const { workspace_ref } = useParams();
    const [workspace, setWorkspace] = useState<any | null>(null)
    const [page, setPage] = useState<string | undefined>(readStorage('lastSetting').page)

    const handlePageChange = (p: SidebarMenuItem) => {
        writeStorage('lastSetting', { page: p.name })
        setPage(p.name)
    }

    const pages: Array<SidebarMenuItem> = [
        {
            name: 'members',
            label: 'settings.sidebar.members',
            icon: <UsersIcon className='h-6 w-6' />,
            rights: [DbRights.SettingsMemberRead, DbRights.SettingsMemberReadWrite, DbRights.SettingsInviteRead, DbRights.SettingsInviteReadWrite],
            action: handlePageChange

        },
        {
            name: 'rights',
            label: 'settings.sidebar.rights',
            icon: <ShieldExclamationIcon className='h-6 w-6' />,
            rights: [DbRights.SettingsRightsReadWrite, DbRights.SettingsRightsRead],
            action: handlePageChange

        },
        {
            name: 'logs',
            label: 'settings.sidebar.audit',
            icon: <DocumentTextIcon className='h-6 w-6' />,
            rights: [DbRights.SettingsAuditLogRead],
            action: handlePageChange

        },
        {
            name: 'chat',
            label: 'settings.sidebar.chat',
            icon: <ChatBubbleLeftRightIcon className='h-6 w-6' />,
            rights: [DbRights.SettingsChatRead, DbRights.SettingsChatReadWrite],
            action: handlePageChange

        },
        {
            name: 'danger',
            label: 'settings.sidebar.danger',
            icon: <ExclamationTriangleIcon className='h-6 w-6 text-red-700 dark:text-red-500' />,
            rights: [DbRights.WorkspaceReadWrite],
            action: handlePageChange
        }
    ]
    const spaceSwitch: SidebarMenuItem = {
        name: ' app',
        label: 'sidebar.application',
        icon: <CommandLineIcon className='h-6 w-6' />,
        rights: [],
        action: () => { window.location.assign(`/workspaces/${workspace_ref}/app`) }
    }

    useEffect(() => {
        if (auth.user?.access_token && workspace_ref) {
            getWorkspace(db, auth.user?.profile.sub, workspace_ref)
                .then((wk) => {
                    setWorkspace(wk)
                })
        }
    }, [auth.user])

    return (
        <PageWithSidebar sidebar={<Sidebar opened={true} selector={true} item={page} items={pages} spaceSwitch={spaceSwitch} />}>
            {
                {
                    'chat': <WorkspaceChat workspaceConfig={workspace} workspace_ref={workspace_ref || ''} />,
                    'members': <WorkspaceMembers workspaceConfig={workspace} workspace_ref={workspace_ref || ''} />,
                    'rights': <WorkspaceRights workspaceConfig={workspace} workspace_ref={workspace_ref || ''} />,
                    'logs': <WorkspaceSettingsAuditLogs workspaceConfig={workspace} workspace_ref={workspace_ref || ''} />,
                    'danger': <WorkspaceDangerZone workspaceConfig={workspace} workspace_ref={workspace_ref || ''} />,
                }[page || 'members']
            }
        </PageWithSidebar >
    )
}