import { Fragment, useEffect, useRef, useState } from "react";
import { WorkspaceProps } from "../Index";
import { PageTitle } from "../../../components/Elements/Title";
import { useTranslation } from "react-i18next";
import { Alert, Button, Label, Spinner, Textarea } from "flowbite-react";
import { FileDrop } from "../../../components/Elements/FileDrop";
import { audioBufferToWav } from "../../../services/Audio";
import { ArrowPathIcon, ArrowRightIcon, CheckIcon, TrashIcon, WifiIcon } from "@heroicons/react/24/outline";
import { httpApiPost } from "../../../services/HttpApi";
import { useAuth } from "react-oidc-context";
import { Card } from "../../../components/Elements/Card";
import { DbContextInterface, DbContextMessageInterface, DbRights } from "../../../config/Db";
import { deleteContextMessage, deleteContextMessages, getContext, insertContextMessage, updateContext } from "../../../services/Context";
import { useDb } from "../../../services/Db";
import { NameEditable } from "../../../components/Elements/NameEditable";
import Moment from "react-moment";
import { useForm } from "react-hook-form";
import { useToast } from "../../../components/Contexts/ToastContext";
import { UserHasRights } from "../../../services/Workspace";
import { usePrevious } from "../../../components/Previous";
import { ChatCard } from "../../../components/Elements/Chatcard";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { AudioRecorder } from "../../../components/Elements/AudioRecorder";

export interface TranscriptAssistantProps extends WorkspaceProps {
    contextRef: string | undefined
    updateContextTitle: (identifier: number, value: string) => void
    removeCurrentContext: () => void
}

interface TranscriptProps extends WorkspaceProps {
    context?: DbContextInterface
    wsState: ReadyState
    setTranscriptText: (transcript: string | undefined, download: boolean) => void
}

interface chatMessage {
    role: 'system' | 'user' | 'assistant' | 'human' | 'robot',
    content: string
    end?: boolean
}

const getMediaLink = (src: Blob | ArrayBuffer | string, type: string): string => {
    if (src instanceof Blob) {
        return URL.createObjectURL(src)
    } else {
        const blob = new Blob([src], { type: type })
        return URL.createObjectURL(blob)
    }
}

const downloadLink = (url: string, name: string,) => {
    const a = document.createElement('a')
    a.href = url
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}



export function ApplicationTranscript(props: TranscriptProps) {
    const auth = useAuth()
    const [audioBuffer, setAudioBuffer] = useState<ArrayBuffer | undefined>(undefined)
    const [duration, setDuration] = useState<string>('')
    const [running, setRunning] = useState<boolean>(false)
    const { t } = useTranslation()

    const readMedia = (file: File): Promise<void> => {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                const arrBuffer: ArrayBuffer = event.target?.result as ArrayBuffer;
                createAudio(arrBuffer).then(() => {
                    return resolve()
                })
            };
            reader.readAsArrayBuffer(file);
        })
    }

    const extractText = (file: File): Promise<void> => {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                props.setTranscriptText(event.target?.result as string, false)
                return resolve()
            }
            reader.readAsText(file);
        })
    }

    const createAudio = (arrBuffer: ArrayBuffer): Promise<void> => {
        return new Promise((resolve, _) => {
            const audioCtx = new AudioContext({ sampleRate: 8000 });
            audioCtx.decodeAudioData(arrBuffer, function (audioBuffer: AudioBuffer) {
                const minutes = Math.max(1, ((audioBuffer.duration * 200) / (3000 * 60))).toFixed(0)
                setDuration(minutes) // Duration in minutes
                const buffer = audioBufferToWav(audioBuffer, false, true)
                setAudioBuffer(buffer)
                resolve()
            });
        })
    }

    const handleInput = (file: File): Promise<void> => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            if (file.type.startsWith('audio/') || file.type.startsWith('video/')) {
                return readMedia(file)
            } else if (file.type.startsWith('text/')) {
                return extractText(file)
            }
        }
        return Promise.reject()
    }

    const handleTranscript = () => {
        setRunning(true)
        httpApiPost(`/workspaces/${props.workspaceConfig.workspace.ref}/transcript`, audioBuffer, auth.user?.access_token || '')
            .then((value) => {
                setAudioBuffer(undefined)
                props.setTranscriptText(value.text, true)
                setRunning(false)
            }).catch(() => {
                props.setTranscriptText(undefined, false)
                setRunning(false)
            })
    }



    return (
        <>
            {
                audioBuffer === undefined && running == false ?
                    <div className="w-full mb-4 flex flex-row gap-2 flex-wrap items-stretch">
                        <div className="flex-1 h-full">
                            <FileDrop width="w-full h-full" text='application.transcript.filedrop' fileHandler={handleInput}></FileDrop>
                        </div>
                        <div className="flex-1">
                            <AudioRecorder width="w-full h-full" handle={createAudio}></AudioRecorder>
                        </div>
                    </div> : <></>
            }
            {
                audioBuffer !== undefined && running == false ?
                    <div className="w-full">
                        <Card title={t('application.transcript.audio.title')} fullwidth={true} collapsible={true}>
                            <div className="flex flex-row justify-center">
                                <figure>
                                    <audio controls src={getMediaLink(audioBuffer, 'audio/wav')}></audio>
                                </figure>
                            </div>
                        </Card>
                        <div className="mt-4">
                            {
                                props.wsState == ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite]) ?
                                    <Button disabled={audioBuffer === undefined} color="action" onClick={handleTranscript} className="ml-auto mr-auto px-8 mt-4">{t('application.transcript.start')} <ArrowRightIcon className="ml-2 h-5 w-5"></ArrowRightIcon></Button>
                                    :
                                    <div>
                                        <Alert color="failure" icon={WifiIcon}>
                                            <span className="font-bold">{t('application.transcript.wait')}</span>
                                        </Alert>
                                    </div>

                            }
                        </div>
                    </div>

                    : <></>
            }
            {
                running == true ?
                    <div className="flex flex-col gap-2 items-center mt-4">
                        <div><Spinner color='info' aria-label="Transcript spinner" size="xl" /></div>
                        <div className="text-gray-900 dark:text-gray-200">{t('application.transcript.bepatient', { minutes: duration })}</div>
                    </div> : <></>

            }
        </>
    )
}




export function ApplicationTranscriptAssistant(props: TranscriptAssistantProps) {

    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)
    const { t, i18n } = useTranslation()
    const toast = useToast()
    const { register, setValue, getValues, handleSubmit, formState: { isSubmitting, isDirty, isValid } } = useForm({ mode: "onChange" });

    /**
     * Transcript phase variables
     */
    const [context, setContext] = useState<DbContextInterface | undefined>(undefined)
    const previousContext = usePrevious(props.contextRef)

    // Transcript content, set for review
    const [transcript, setTranscript] = useState<string | undefined>(undefined)
    const transcriptRef = useRef<HTMLTextAreaElement | null>(null)

    /**
     * Chat variables
     */
    const [chatMessages, setChatMessages] = useState<Array<DbContextMessageInterface>>([])
    const [lastMessage, setLastMessage] = useState<chatMessage | undefined>(undefined)
    //const messageInputRef = useRef<HTMLInputElement>(null)
    const messagesEndRef = useRef<HTMLDivElement>(null)
    const { sendJsonMessage, readyState } = useWebSocket(
        `${import.meta.env.VITE_WS_URL}/workspaces/${props.workspace_ref}/transcript-chat`,
        {
            share: false,
            reconnectAttempts: 360,
            reconnectInterval: 10,
            shouldReconnect: () => true,
            onClose: () => { },
            onMessage: (event: WebSocketEventMap["message"]) => {
                const msg: chatMessage = JSON.parse(event.data)
                if (msg.end == true && context) {
                    insertContextMessage(db, context.id, 'assistant', msg.content).then(() => {
                        setChatMessages(prev => { return [...prev, msg] })
                        setLastMessage(undefined)
                        scrollToBottom()
                    })
                } else {
                    setLastMessage(msg)
                    scrollToBottom()
                }
            },
        }
    )

    /**
     * Common methods
     */

    const updateContextTitle = (id: number, title: string) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            setContext(prev => {
                if (prev) {
                    return {
                        ...prev,
                        name: title
                    }
                }
            })
            props.updateContextTitle(id, title)
        }
    }


    /**
     * Transcript phase methods
     */
    const handlePromptSubmit = (data: any) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            if (context) {
                updateContext(db, context.id, data).then(() => {
                    toast.fire('success', t('application.transcript.prompts.success'), 2000)
                }).catch(() => {
                    toast.fire('failure', t('application.transcript.prompts.failure'), 2000)
                })
            }
        }
    }

    const newTranscript = () => {
        if (context && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            deleteContextMessages(db, context.id).then(() => {
                updateContext(db, context.id, { transcript: null }).then(() => {
                    setTranscript(undefined)
                    reloadContext()
                })
            })
            // TODO: drop saved messages and saved transcript
        }
    }

    /**
     * Chat phase methods
     */
    const sendMessage = (msg: string) => {
        if (context && msg.trim().length > 0 && props.workspaceConfig?.workspace && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            insertContextMessage(db, context.id, 'user', msg).then(() => {
                setChatMessages(prev => {
                    const n = [...prev, { role: 'user', content: msg }]
                    sendJsonMessage({
                        auth: { token: auth.user?.access_token },
                        messages: n
                    })
                    return n
                })
            }).catch(() => {
                toast.fire('failure', t('application.transcript.chat.error'), 3000)
            })
        }
    }

    const startConversation = () => {
        if (context) {
            updateContext(db, context.id, { transcript: transcriptRef.current?.value }).then(() => {
                insertContextMessage(db, context.id, 'system', getValues('system_prompt'), true).then(() => {
                    insertContextMessage(db, context.id, 'user', `${getValues('user_prompt')}\n\n${transcriptRef.current?.value}`, true).then(() => {
                        const initial = [
                            {
                                role: 'system',
                                content: getValues('system_prompt'),
                                hidden: true
                            },
                            {
                                role: 'user',
                                content: `${getValues('user_prompt')}\n\n${transcriptRef.current?.value}`,
                                hidden: true
                            }
                        ]
                        setChatMessages(initial)
                        sendJsonMessage({
                            auth: { token: auth.user?.access_token },
                            messages: initial
                        })
                    }).catch(() => {
                        toast.fire('failure', t('application.transcript.chat.error'), 3000)
                    })
                }).catch(() => {
                    toast.fire('failure', t('application.transcript.chat.error'), 3000)
                })
            }).catch(() => {
                toast.fire('failure', t('application.transcript.chat.error'), 3000)
            })
        }
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'instant' })
    }

    const reloadContext = () => {
        if (props.workspaceConfig?.workspace && props.workspaceConfig.workspace.id && props.contextRef && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            getContext(db, props.workspaceConfig.workspace.id, props.contextRef).then((value: DbContextInterface | undefined) => {
                setContext(value)
                setChatMessages(value?.context_message || [])
                if (value?.transcript) {
                    setTranscript(value.transcript)
                }
                if (previousContext != props.contextRef) {
                    setValue('system_prompt', value?.system_prompt)
                    setValue('user_prompt', value?.user_prompt)
                }
            })
        } else {
            setContext(undefined)
        }
    }


    /**
     * UseEffects
     */
    // On contextRef of Workspace changes
    useEffect(() => {
        reloadContext()
    }, [props.workspaceConfig?.workspace, props.contextRef])

    useEffect(() => {
        if (chatMessages.length > 0) {
            scrollToBottom()
        }
    }, [chatMessages])

    return (
        <>
            {
                props.workspaceConfig?.role && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptRead, DbRights.AppTranscriptReadWrite]) && context ?
                    <>
                        {/* Title */}
                        <PageTitle
                            margin='mb-4'
                            rightbutton={
                                <div className="flex flex-row gap-2">
                                    <div>
                                        <Button aria-label='New transcript' color="transparent" onClick={() => { newTranscript() }}>
                                            <ArrowPathIcon className="text-gray-800 dark:text-gray-200 h-5 w-5" />
                                        </Button>
                                    </div>
                                    <Button aria-label='Remove current assistant' color="transparent" onClick={() => { props.removeCurrentContext() }}>
                                        <TrashIcon className="text-red-800 dark:text-red-600 h-5 w-5" />
                                    </Button>
                                </div>

                            }
                            subtitle={<span>{t('application.transcript.context.created')} <Moment locale={i18n.language} fromNow>{context.created_at}</Moment></span>}
                        >
                            <NameEditable className='p-1' name={context.name} identifier={context.id} save={updateContextTitle} maxchars={52} />
                        </PageTitle>


                        <div className="flex flex-col h-[calc(100%-190px)] gap-2 items-center w-full">
                            <div className="flex flex-col gap-2 w-full items-center overflow-y-scroll no-scrollbar">
                                {/* Form with prompts, always displayed */}
                                <div className="flex flex-col gap-2 w-full items-center mb-4">
                                    <Card title={t('application.transcript.prompts.title')} fullwidth={true} collapsible={true} expandable={true} collapsed={true}>
                                        <form onSubmit={handleSubmit((data) => handlePromptSubmit(data))} className="flex flex-col gap-4">
                                            <div>
                                                <Label className="font-bold">{t('application.transcript.prompts.system')}</Label>
                                                <Textarea rows={8} {...register("system_prompt", { required: t('application.transcript.prompt.system.required') })}></Textarea>
                                            </div>
                                            <div>
                                                <Label className="font-bold">{t('application.transcript.prompts.user')}</Label>
                                                <Textarea rows={3} {...register("user_prompt")}></Textarea>
                                            </div>
                                            <Button className='w-48 ml-auto' id='prompts-submit' color="submit" type="submit" disabled={!isDirty || !isValid}>
                                                {t('application.transcript.prompts.button')}
                                                {isSubmitting ? <Spinner className="ml-2" color="info" aria-label="Submitting spinner" />
                                                    : <CheckIcon className="ml-2 h-5 w-5" />}
                                            </Button>
                                        </form>
                                    </Card>
                                </div>
                                { /* transcript panel, displayed only if there is a transcript */}
                                {
                                    transcript ?
                                        <div className="flex flex-col gap-2 items-center w-full mb-4">
                                            <div className="w-full">
                                                <Card title={t('application.transcript.text.title')} fullwidth={true} collapsible={true} expandable={true} collapsed={true}>
                                                    <div className="flex flex-row justify-center w-full">
                                                        <Textarea rows={10} ref={transcriptRef} defaultValue={transcript}></Textarea>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                        : <></>
                                }
                                { /* Start transcript button, displayed only if there is a transcript and there is no chat */}
                                {
                                    transcript && chatMessages.filter((cm: DbContextMessageInterface) => { return cm.hidden != true }).length == 0 && !lastMessage ?
                                        <div className="flex flex-col gap-2 items-center w-full">
                                            <div>
                                                <Button
                                                    color="action"
                                                    className="px-8"
                                                    aria-label={t('application.transcript.chat.start')}
                                                    onClick={() => { startConversation() }}
                                                >{t('application.transcript.chat.start')} <ArrowRightIcon className="ml-2 h-5 w-5"></ArrowRightIcon></Button>
                                            </div>
                                        </div> : <></>
                                }
                                { /* Drag&Drop or record, displayed if no chat message and no transcript */}
                                {
                                    !transcript && chatMessages.filter((cm: DbContextMessageInterface) => { return cm.hidden != true }).length == 0 ?
                                        <ApplicationTranscript
                                            context={context}
                                            wsState={readyState}
                                            workspaceConfig={props.workspaceConfig}
                                            workspace_ref={props.workspace_ref}
                                            setTranscriptText={(tr: string | undefined, download: boolean) => {
                                                if (tr) {
                                                    updateContext(db, context.id || 0, {
                                                        transcript: tr
                                                    }).then(() => {
                                                        if (download) {
                                                            downloadLink(getMediaLink(tr, 'text/plain'), `${context.name}-transcript.txt`)
                                                        }
                                                    })
                                                }
                                                setTranscript(tr)
                                            }}
                                        />
                                        : <></>
                                }
                                { /* Chat messages */}
                                {
                                    chatMessages.map((value: DbContextMessageInterface, index: number) => (
                                        <Fragment key={index}>
                                            {
                                                value.hidden != true ? <ChatCard
                                                    key={value.id}
                                                    role={value.role}
                                                    user={auth.user?.profile}
                                                    remove={() => {
                                                        deleteContextMessage(db, context.id, value?.id || 0).then(() => {
                                                            setChatMessages(prev => {
                                                                return prev.filter((m: DbContextMessageInterface) => { return m.id != value.id })
                                                            })
                                                        })
                                                    }}
                                                    download={() => {
                                                        downloadLink(getMediaLink(value.content, 'text/markdown'), `${context.name}.md`)
                                                    }}
                                                >{value.content}</ChatCard>
                                                    : <></>
                                            }
                                        </Fragment>
                                    ))
                                }
                                { /* Last chat message from assistant to display running answer */}
                                {
                                    lastMessage ? <ChatCard role={lastMessage.role} user={auth.user?.profile}>{lastMessage.content}</ChatCard> : <></>
                                }
                                <div ref={messagesEndRef} className='justify-end items-end'></div>
                            </div>
                        </div>
                        {
                            chatMessages.filter((cm: DbContextMessageInterface) => { return cm.hidden != true }).length > 0 ?
                                <div className="flex flex-col justify-end items-center bottom-4 w-full bg-transparent">
                                    <div className="text-xs text-gray-500 w-2/3">{
                                        readyState == ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite]) ?
                                            t('application.transcript.chat.submit') :
                                            t('application.transcript.wait')
                                    }</div>
                                    <NameEditable
                                        className='mb-2 mx-4 shadow-lg w-2/3 px-2 py-3 rounded rounded-lg border border-primary-700 max-h-80 min-h-20 overflow-y-scroll'
                                        name=""
                                        editable={readyState == ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite])}
                                        needSubmit={(event) => { return (event.ctrlKey && event.key == 'Enter') || (event.metaKey && event.key == 'Enter') }}
                                        save={(_: number, value: string) => { sendMessage(value) }}
                                    />
                                </div> : <></>
                        }
                    </> : <></>
            }
        </>
    )
}