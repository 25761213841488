
import { PropsWithChildren, useState } from 'react'

import { Card as FlowbiteCard } from 'flowbite-react'
import { ArrowsPointingInIcon, ArrowsPointingOutIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline'


export interface CardProps {
    title?: string
    subtitle?: string
    maxwidth?: string
    fullwidth?: boolean
    collapsible?: boolean
    collapsed?: boolean
    expandable?: boolean
}

export const Card = (props: PropsWithChildren<CardProps>) => {

    const [collapsed, setCollasped] = useState<boolean>(props.collapsed || false)
    const [expanded, setExpanded] = useState<boolean>(false)

    const handleExpand = (exp: boolean) => {
        setExpanded(exp)
    }


    return (
        <FlowbiteCard className={
            `${expanded ? 'fixed top-1 bottom-1 left-1 right-1 z-40' : ''} border border-gray-300 bg-white
            shadow-md dark:border-white dark:bg-gray-800
            ${props.maxwidth ? `max-w-${props.maxwidth}` : ''}
            overflow-y-scroll
            ${props.fullwidth ? "w-full" : ""}`
        }>
            <div className='flex flex-row justify-between items-center'>
                <div>
                    {
                        props.title ?
                            <div>
                                <h2 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    {props.title}
                                </h2>
                                <div className='text-gray-600 italic dark:text-gray-200 text-sm'>
                                    {props.subtitle}
                                </div>
                            </div>
                            :
                            <></>
                    }
                </div>
                <div className='flex flex-row gap-2'>
                    <div>
                        {
                            props.collapsible && !expanded ?
                                collapsed ?
                                    <PlusIcon onClick={() => setCollasped(false)} cursor='pointer' className='h-5 w-5 text-gray-900 dark:text-gray-100'></PlusIcon>
                                    :
                                    <MinusIcon onClick={() => setCollasped(true)} cursor='pointer' className='h-5 w-5 text-gray-900 dark:text-gray-100'></MinusIcon>
                                : <></>
                        }
                    </div>
                    <div>
                        {
                            props.expandable && !collapsed ?
                                expanded ?
                                    <ArrowsPointingInIcon onClick={() => handleExpand(false)} cursor='pointer' className='h-5 w-5 text-gray-900 dark:text-gray-100'></ArrowsPointingInIcon>
                                    :
                                    <ArrowsPointingOutIcon onClick={() => handleExpand(true)} cursor='pointer' className='h-5 w-5 text-gray-900 dark:text-gray-100'></ArrowsPointingOutIcon>
                                : <></>
                        }
                    </div>
                </div>
            </div>
            <div className={`${collapsed ? 'hidden' : 'block'}`}>
                {props.children}
            </div>
        </FlowbiteCard>
    )
}