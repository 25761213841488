// React
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
// Flowbite & icons
import { Flowbite } from 'flowbite-react'
// External packages
import { AuthProvider } from 'react-oidc-context'
// Services
import i18n from './services/i18n';
// Components & Conttexts
import { PrivateRoute } from './components/PrivateRoute'
import { ToastContextProvider } from './components/Contexts/ToastContext'
// Config
import routes from './config/Routes'
import { oidcConfig, onSigninCallback } from './config/Auth'
import { templateTheme } from './config/Theme'



const App = () => {
  document.title = import.meta.env.VITE_PROJECT_NAME
  const lnk = document.createElement('link')
  lnk.setAttribute('rel', 'preconnect')
  lnk.setAttribute('href', import.meta.env.VITE_DBAPI_URL)
  document.head.appendChild(lnk);
  document.documentElement.lang = i18n.language

  const config = oidcConfig(window.location.origin)
  return (
    <Flowbite theme={{ theme: templateTheme }}>
      <AuthProvider {...config} onSigninCallback={onSigninCallback}>
        <ToastContextProvider>
          <BrowserRouter>
            <Routes>
              {routes.map((route) => (
                route.private ?
                  (<Route key={route.path} path={route.path} element={<PrivateRoute />}>
                    <Route key={route.path} path={route.path} element={route.component} />
                  </Route>) :
                  (<Route
                    key={route.path}
                    path={route.path}
                    element={route.component}
                  />)
              ))
              }
              <Route path="*" element={<Navigate to='/' />} />
            </Routes>
          </BrowserRouter>
        </ToastContextProvider>
      </AuthProvider>
    </Flowbite>
  );
}
export default App;