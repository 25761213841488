import { MicrophoneIcon, StopIcon } from "@heroicons/react/24/outline";
import { Label } from "flowbite-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "../Contexts/ToastContext";

export interface FileDropProps {
    width: string
    text?: string
    handle: (buffer: ArrayBuffer) => Promise<void>
}


export function AudioRecorder(props: FileDropProps) {
    const { t } = useTranslation()
    const [recording, setRecording] = useState<boolean>(false)
    const [recorder, setRecorder] = useState<MediaRecorder | undefined>(undefined)
    const toast = useToast()


    const audioDecoder = (blob: Blob): Promise<AudioBuffer> => {
        return new Promise((resolve, reject) => {
            const audioCtx = new AudioContext({ sampleRate: 8000 });
            blob.arrayBuffer().then((buffer: ArrayBuffer) => {
                audioCtx.decodeAudioData(buffer, resolve, reject)
            })
        })
    }

    const drawWave = (canvas: HTMLCanvasElement | null, audioBuffer: AudioBuffer) => {
        if (!canvas) return
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        if (!ctx) return
        ctx.fillStyle = '#ff0000'
        const data: Float32Array = audioBuffer.getChannelData(0)
        const steps = Math.ceil(data.length / canvas.width)

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < canvas.width; i++) {
            let min: number = 1.0
            let max: number = -1.0
            for (let j = 0; j < steps; j++) {
                const d = data[(i * steps) + j];
                min = Math.min(d, min)
                max = Math.max(d, max)
            }
            ctx.fillRect(i, (1 + min) * (canvas.height / 2), 1, Math.max(1, (max - min) * (canvas.height / 2)));
        }
    }

    useEffect(() => {
        if (recording == false) {
            if (recorder) {
                // Need to stop record
                recorder.stop()
            }
        } else {
            if (!recorder) {
                navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                    const chunks: Array<Blob> = [];
                    const rec = new MediaRecorder(stream);
                    rec.ondataavailable = e => {
                        chunks.push(e.data);
                        audioDecoder(new Blob(chunks, { type: 'audio/webm' })).then((audioBuffer: AudioBuffer) => {
                            drawWave(document.getElementById('wareform') as HTMLCanvasElement, audioBuffer)
                        })
                    };
                    rec.onstop = () => {
                        // convert stream data chunks to a 'webm' audio format as a blob
                        const rawAudio = new Blob(chunks, { type: 'audio/webm' });
                        rawAudio.arrayBuffer().then((arrBuffer: ArrayBuffer) => {
                            props.handle(arrBuffer).then(() => {
                                setRecording(false)
                                setRecorder(undefined)
                            })
                        })
                    }
                    rec.start(500);
                    setRecorder(rec)
                }).catch(() => {
                    toast.fire('failure', t('application.transcript.audio.notallowed'), 2000)
                    setRecording(false)
                })
            }
        }
    }, [recorder, recording])

    return (
        <div className={`${props.width} flex items-center justify-center`}>
            <Label
                className={`flex p-2 h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed ${recording ? 'animate-pulse border-red-600 bg-red-100 hover:bg-red-200 dark:border-red-600 dark:bg-red-100' : 'border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600'} `}
                onClick={() => setRecording(prev => { return !prev })}
            >
                <div className="w-full flex flex-col items-center justify-center px-2 pb-6 pt-5">
                    {
                        recording ?
                            <>
                                <canvas id='wareform' className="h-12 w-full"></canvas>
                                <StopIcon className="h-8 w-8"></StopIcon>
                                <div className="mb-2 text-center text-sm text-gray-900 dark:text-gray-100">
                                    {t(props.text || 'general.stoprecord')}
                                </div>
                            </>
                            : <>
                                <div>
                                    <MicrophoneIcon className="h-8 w-8"></MicrophoneIcon>
                                </div>
                                <div className="mb-2 text-center text-sm text-gray-900 dark:text-gray-100">
                                    {t(props.text || 'general.record')}
                                </div>
                            </>
                    }

                </div>
            </Label >
        </div >
    );
}