import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getWorkspaces } from "../../services/Workspace";
import { useAuth } from "react-oidc-context";
import { DbUserWorkspaceInterface } from "../../config/Db";
import { useDb } from "../../services/Db";

export interface WorkspaceProps {
    workspaceConfig: DbUserWorkspaceInterface
    workspace_ref: string
}


export default function WorkspaceIndex() {
    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)

    const [workspaces, setWorkspaces] = useState<Array<DbUserWorkspaceInterface> | null>(null)
    useEffect(() => {
        if (auth.user?.access_token) {
            getWorkspaces(db, auth.user?.profile.sub)
                .then((wks: Array<DbUserWorkspaceInterface>) => {
                    setWorkspaces(wks)
                })
        }
    }, [auth.user])

    return (
        <>
            {
                workspaces && workspaces?.length == 0 ?
                    <Navigate to='/workspace'></Navigate>
                    : workspaces && workspaces?.length > 0 ?
                        <Navigate to={`/workspaces/${workspaces[0].workspace.ref}/app`}></Navigate>
                        :
                        <></>
            }
        </>
    )

}