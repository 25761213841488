import React, { PropsWithChildren, useEffect, useState } from "react";
import { Nav } from "./Nav"
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

export interface PageProps {
    hcenter?: boolean
    vcenter?: boolean
}

export interface PageWithSidebarProps {
    sidebar: React.ReactElement
}

export const Page = (props: PropsWithChildren<PageProps>) => {
    return (
        <>
            <Nav />
            <div className='mt-14 pt-4 h-[calc(100vh-56px)]'>
                <div className={`flex flex-col ${props.hcenter ? "items-center" : "desktop:px-20 laptop:px-16 tablet:px-8 phone:px-2"} ${props.vcenter ? 'h-full justify-center' : ''}`}>
                    {props.children}
                </div>
            </div>
        </>
    )
}

export const PageWithSidebar = (props: PropsWithChildren<PageWithSidebarProps>) => {

    const isPhone = () => window.innerWidth < 768
    const [open, setOpen] = useState<boolean>(!isPhone());

    useEffect(() => {
        const handleResize = () => {
            setOpen(!isPhone())
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <Nav></Nav>
            <div className='flex flex-row mt-14 h-[calc(100vh-56px)] overflow-y-auto'>
                <ChevronLeftIcon className={`fixed cursor-pointer left-16 top-4 z-30 w-6 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border rounded-full  ${!open && "rotate-180"}`}
                    onClick={() => setOpen(!open)}></ChevronLeftIcon>
                <div className={`${open ? "w-72" : isPhone() ? "hidden" : "w-14"}`}>
                    {React.cloneElement(props.sidebar, { opened: open })}
                </div>
                <div className="flex flex-1 flex-col tablet:px-4 tablet:pt-4 phone:pt-2 phone:px-2 overflow-y-auto">
                    {props.children}
                </div>
            </div>
        </>
    )
}