export interface FlagProps {
    country: string
    className?: string
}

export const Flag = (props: FlagProps) => {
    return (
        <img src={`/flags/${props.country}.svg`} role="img" alt={`${props.country} flag`} className={props.className}></img >
    )
}
