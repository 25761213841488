import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Link } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

type MarkdownRendererProps = {
  children: string
};


export function MarkdownRenderer(props: MarkdownRendererProps) {

  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={
        {
          code({ _node, inline, className, children, ...props }: any) {
            return (
              <code className={`${className} bg-gray-300 dark:text-gray-900`} {...props}>
                {children}
              </code>)
          },
          ul: ({ node, className, children, ...props }): any => {
            return <ul className='ul list-disc	list-inside	ml-4' {...props}>{children}</ul>
          },
          ol: ({ node, className, children, ...props }): any => {
            return <ol className='ol list-decimal list-inside	ml-4' {...props}>{children}</ol>
          },
          a: ({ children, href }): any => {
            return <Link className='' to={href || ''}>{children} <ArrowTopRightOnSquareIcon className='h-3 w-3 inline align-baseline'></ArrowTopRightOnSquareIcon></Link>
          },
          p: ({ children }): any => {
            return <p className='text-gray-900 dark:text-gray-100'>{children}</p>
          }
        }
      }
    >
      {props.children}
    </Markdown>
  );
}