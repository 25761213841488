// React
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import { PostgrestSingleResponse } from '@supabase/postgrest-js'
import { useForm } from "react-hook-form";
import Moment from 'react-moment';
// Flowbite & icons
import { Avatar, Badge, Button, Label, Modal, TextInput } from 'flowbite-react'
import { ArrowPathIcon, ArrowTopRightOnSquareIcon, CheckIcon, UserPlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
// Project components & Templates
import { PageTitle } from '../../../components/Elements/Title'
import { getAvatarImage } from '../../../components/Elements/AvatarImage';
import { useToast } from '../../../components/Contexts/ToastContext'
import { Box } from '../../../components/Elements/Box'
import { BoxSet } from '../../../components/Layout/BoxSet'
import { WorkspaceProps } from '../Index'
// Project services & context
import { useDb, select, insert, remove } from '../../../services/Db'
import { getWorkspaceMembers, UserHasRights } from '../../../services/Workspace'
import { httpApiPost } from '../../../services/HttpApi'
// Project config
import { DbInviteInterface, DbRoleInterface, DbRights, DbUserWorkspaceInterface } from '../../../config/Db'
import { useTranslation } from 'react-i18next'


export default function WorkspaceMembers(props: WorkspaceProps) {

    const auth = useAuth()
    const toast = useToast()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const db = useDb('app', auth.user?.access_token)
    const { t, i18n } = useTranslation()

    const [members, setMembers] = useState<Array<DbUserWorkspaceInterface>>([])
    const [invites, setInvites] = useState<Array<DbInviteInterface>>([])
    const [modal, setModal] = useState<boolean>(false)
    const [roles, setRoles] = useState<Array<DbRoleInterface>>([])
    const [defaultRole, setDefaultRole] = useState<number>(2)


    const sendInviteEmail = (ref: string, to: string) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsInviteReadWrite])) {
            httpApiPost(`/workspaces/${props.workspace_ref}/invites/${ref}`, null, auth.user?.access_token || '').then((inviteResult: any) => {
                if (inviteResult.status_code == 200) {
                    toast.fire('success', t('settings.members.sections.invited.resend.success', { to: to }), 3000)
                } else {
                    toast.fire('failure', t('settings.members.sections.invited.resend.failure'), 3000)
                }
            })
        } else {
            toast.fire('failure', t('settings.members.sections.invited.resend.right'), 3000)

        }
    }

    const cancelInvite = (ref: string, to: string) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsInviteReadWrite])) {
            remove(db, 'invite')
                .eq('ref', ref)
                .then((result: any) => {
                    if (result.error) {
                        toast.fire('failure', t('settings.members.sections.invited.cancel.failure'), 3000)
                    } else {
                        toast.fire('success', t('settings.members.sections.invited.cancel.success', { to: to }), 3000)
                        updateWorkspaceInvites()
                    }
                })
        } else {
            toast.fire('failure', t('settings.members.sections.invited.cancel.right'), 3000)
        }
    }

    const copyToClipboard = (content: string | undefined, to: string) => {
        if (!content) return
        navigator.permissions.query({ name: 'clipboard-write' as PermissionName }).then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
                /* write to the clipboard now */
                navigator.clipboard.writeText(content)
                toast.fire('success', t('settings.members.sections.invited.copy.success', { to: to }), 3000)
            } else {
                toast.fire('failure', t('settings.members.sections.invited.copy.failure'), 3000)
            }
        });
    }

    const getRoles = () => {
        if (props.workspaceConfig) {
            select(db, 'role', 'id,name')
                .then((result: PostgrestSingleResponse<any[]>) => {
                    if (result.error) {
                        console.error(result.error.details)
                        setRoles([])
                    } else {
                        setRoles(result.data)
                        result.data.forEach((role) => {
                            if (role.name == 'Administrator') {
                                setDefaultRole(role.id)
                            }
                        })
                    }
                })
        }
    }

    const updateWorkspaceMembers = () => {
        if (props.workspaceConfig?.workspace && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsMemberRead, DbRights.SettingsMemberReadWrite])) {
            getWorkspaceMembers(db, props.workspaceConfig.workspace)
                .then((members: Array<DbUserWorkspaceInterface>) => setMembers(members))
                .catch(() => setMembers([]))
        } else {
            setMembers([])
        }
    }

    const updateWorkspaceInvites = () => {
        if (props.workspaceConfig?.workspace && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsInviteRead, DbRights.SettingsInviteReadWrite])) {
            select(db, 'invite', 'ref,user(email,first_name,last_name),to_user_email,to_user_ref,created_at,status,role(name)')
                .eq('workspace_id', props.workspaceConfig.workspace?.id)
                .eq('from_user_ref', auth.user?.profile.sub)
                .in('status', ['created', 'opened', 'sent'])
                .then((result: PostgrestSingleResponse<any[]>) => {
                    if (result.error) {
                        console.error(result.error.details)
                        setInvites([])
                    }
                    else {
                        setInvites(result.data)
                    }
                })
        } else {
            setInvites([])
        }
    }

    const inviteSubmitHandler = (data: any): any => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsInviteReadWrite])) {
            if (members.filter(member => member.user.email == data.email).length == 1) {
                setModal(false)
                toast.fire('failure', t('settings.members.sections.invite.form.errors.useralreadypart', { user: data.email }), 3000)
                return Promise.resolve([])
            }
            return select(db, 'invite')
                .eq('workspace_id', props.workspaceConfig.workspace.id)
                .eq('to_user_email', data.email)
                .in('status', ['created', 'sent', 'opened'])
                .then((result: PostgrestSingleResponse<any[]>) => {
                    if (result.error) {
                        console.error(result.error.details)
                        return []
                    }
                    else {
                        if (result.data.length == 0) {
                            // Need to create an invite
                            insert(db, 'invite', {
                                workspace_id: props.workspaceConfig.workspace.id,
                                to_user_email: data.email,
                                from_user_ref: auth.user?.profile.sub,
                                role_id: data.role
                            }).single()
                                .then((result2: PostgrestSingleResponse<any>) => {
                                    if (result2.error) {
                                        console.error(result2.error.details)
                                        setModal(false)
                                    }
                                    else {
                                        const insertedData = result2.data as DbInviteInterface
                                        setModal(false)
                                        sendInviteEmail(insertedData.ref, data.email)
                                        updateWorkspaceInvites()
                                        return insertedData
                                    }
                                })
                        }
                        else {
                            // A pending invite is still in progress
                            setModal(false)
                            toast.fire('failure', t('settings.members.sections.invite.form.errors.useralreadyinvite', { user: data.email }), 3000)
                        }
                    }
                })
        } else {
            setModal(false)
            toast.fire('failure', t('settings.members.sections.invite.form.errors.right'), 3000)
        }
    }

    const removeFromWorkspace = (user: string, success: string, failure: string, right: string) => {
        // Assume that there is a unique couple (user_ref,workspace_id)
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsMemberReadWrite])) {
            remove(db, 'user_workspace')
                .eq('user_ref', user)
                .eq('workspace_id', props.workspaceConfig.workspace?.id)
                .then((result) => {
                    if (result.error) {
                        toast.fire('failure', failure, 3000)

                    } else {
                        toast.fire('success', success, 3000)
                        updateWorkspaceMembers()
                    }
                })
        } else {
            toast.fire('failure', right, 3000)
        }
    }

    // Workspace update
    useEffect(() => {
        updateWorkspaceMembers()
        updateWorkspaceInvites()
        getRoles()
    }, [props.workspaceConfig, auth.user])

    return (
        <>

            <PageTitle
                subtitle={t('settings.members.subtitle')}
                rightbutton={UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsInviteReadWrite]) ?
                    <Button name='invite-new-user' color='action' onClick={() => setModal(true)}>
                        <span>{t('settings.members.invite')}</span>
                        <UserPlusIcon className='ml-2 h-5 w-5' />
                    </Button>
                    :
                    <></>}
            >
                {t('settings.members.title')}
            </PageTitle >

            <div className='py-4'>
                <BoxSet title={t('settings.members.sections.active.title')}>
                    {
                        UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsMemberRead, DbRights.SettingsMemberReadWrite]) && members.map((member) => (
                            <Box
                                size='min-w-80'
                                key={member.user.ref}
                                title={
                                    <div className='flex flex-row gap-2 items-center'>
                                        <Avatar size={'xs'} alt="User name" img={getAvatarImage(member.user.first_name, member.user.last_name, 48)} rounded />
                                        {member.user.first_name} {member.user.last_name}
                                    </div>
                                }
                                subtitle={member.user.email}
                                action={
                                    <>
                                        {
                                            UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsMemberReadWrite]) ?
                                                member.user.ref == auth.user?.profile.sub ?
                                                    <Button onClick={() => {
                                                        removeFromWorkspace(
                                                            member.user.ref || '',
                                                            t('settings.members.sections.active.leave.success'),
                                                            t('settings.members.sections.active.leave.failure'),
                                                            t('settings.members.sections.active.leave.right'),
                                                        )
                                                    }} size="xs" color="emergency">
                                                        {t('settings.members.sections.active.leave.button')}
                                                    </Button> :
                                                    <Button onClick={() => {
                                                        removeFromWorkspace(
                                                            member.user.ref || '',
                                                            t('settings.members.sections.active.remove.success'),
                                                            t('settings.members.sections.active.remove.failure'),
                                                            t('settings.members.sections.active.remove.right'),
                                                        )
                                                    }} size="xs" color="emergency">
                                                        {t('settings.members.sections.active.remove.button')}
                                                    </Button>
                                                : <></>
                                        }
                                    </>
                                }
                            >
                                <div className='flex flex-row gap-2'>
                                    <Badge className='justify-center' color="blue">{t(member.role.name)}</Badge>
                                    {
                                        member.user.ref == auth.user?.profile.sub ?
                                            <Badge className='justify-center' color="green">{t('general.me')}</Badge>
                                            :
                                            <></>
                                    }
                                </div>
                            </Box>
                        ))
                    }
                </BoxSet>
            </div >

            {
                invites.length > 0 && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsInviteRead, DbRights.SettingsInviteReadWrite]) ?
                    <div className='py-4'>
                        <BoxSet title={t('settings.members.sections.invited.title')}>
                            {
                                invites.map((invite, index) => (
                                    <Box
                                        key={index}
                                        size='min-w-80'
                                        title={invite.to_user_email}
                                        subtitle={
                                            <>
                                                {
                                                    t('settings.members.sections.invited.by', {
                                                        first_name: invite.user?.first_name || '', last_name: invite.user?.last_name || ''
                                                    })
                                                }
                                                <span> <Moment locale={i18n.language} fromNow>{invite.created_at}</Moment></span>
                                            </>
                                        }
                                        action={
                                            UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsInviteReadWrite]) ?
                                                <div className='flex flex-row justify-end gap-2'>
                                                    <Button onClick={() => cancelInvite(invite.ref, invite.to_user_email)} size="xs" color="emergency">
                                                        <span className='phone:hidden tablet:block'>{t('settings.members.sections.invited.cancel.button')}</span>
                                                        <XMarkIcon className='ml-2 h-4 w-4'></XMarkIcon>
                                                    </Button>
                                                    <Button onClick={() => { sendInviteEmail(invite.ref, invite.user?.email || '') }} size="xs" color="action">
                                                        <span className='phone:hidden tablet:block'>{t('settings.members.sections.invited.resend.button')}</span>
                                                        <ArrowPathIcon className='ml-2 h-4 w-4'></ArrowPathIcon>
                                                    </Button>
                                                </div>
                                                : <></>
                                        }
                                    >
                                        <div className='flex flex-row gap-4 items-start'>
                                            {
                                                invite.status == 'created' ?
                                                    <Badge className='justify-center' color="red">{t('settings.members.sections.invited.notseen')}</Badge>
                                                    :
                                                    <Badge className='justify-center' color="yellow">{t('settings.members.sections.invited.notacceptedyet')}</Badge>
                                            }
                                            <Badge className='justify-center' color="blue">{t(invite.role.name)}</Badge>

                                        </div>
                                        <Link className="font-normal text-xs italic text-gray-400 dark:text-gray-200" onClick={() => copyToClipboard(`${window.location.origin}/invites/${invite.ref}`, invite.to_user_email)} to={window.location.href}>
                                            {t('settings.members.sections.invited.copy.button')}<ArrowTopRightOnSquareIcon className="h-3 w-3 inline align-baseline" />
                                        </Link>
                                    </Box>
                                ))
                            }
                        </BoxSet>
                    </div > : <></>
            }
            {
                UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsInviteReadWrite]) ?
                    < Modal show={modal} onClose={() => { setModal(false) }}>
                        <Modal.Header>{t('settings.members.sections.invite.title', { workspace: props.workspaceConfig.workspace?.name })}</Modal.Header>
                        <Modal.Body>
                            <div className="space-y-6">
                                <form onSubmit={handleSubmit((data) => inviteSubmitHandler(data))} className="flex flex-col gap-4">
                                    <div className='flex flex-row gap-2 flex-wrap'>
                                        <div className='flex-shink'>
                                            <Label htmlFor='role' value={t('settings.members.sections.invite.form.role')} />
                                            <select defaultValue={defaultRole} className='block rounded-lg disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-700 focus:ring-primary-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-700 dark:focus:ring-primary-700' {...register("role", { required: true })}>
                                                {
                                                    roles.map((role: DbRoleInterface) => (
                                                        <option key={role.id} value={role.id}>{t(role.name)}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className='flex-grow min-w-60'>
                                            <Label htmlFor='email' value={t('settings.members.sections.invite.form.email')} />
                                            <TextInput autoFocus={true} className='mt-0' type='email' {...register("email", { required: true })} placeholder="email@invite.com"></TextInput>
                                            {errors.email && <span className='text-sm text-red-600 dark:text-red-500'>{t('settings.members.sections.invite.form.errors.required')}</span>}

                                        </div>
                                    </div>
                                    <Button color="submit" type="submit" className='ml-auto'>
                                        {t('settings.members.sections.invite.form.button')}<CheckIcon className="ml-2 h-5 w-5" />
                                    </Button>
                                </form>
                            </div>
                        </Modal.Body>
                    </Modal >
                    : <></>
            }

        </>
    )

}