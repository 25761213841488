import { withAuthenticationRequired } from 'react-oidc-context';
import { Outlet } from 'react-router-dom'
import i18n from '../services/i18n'


export const PrivateRoute = () => {
    const privateContent = () => (<Outlet />);
    const ProtectedContent = withAuthenticationRequired(privateContent, {
        OnRedirecting: () => (<></>),
        signinRedirectArgs: {
            redirect_uri: `${window.location.href}`,
            ui_locales: i18n.language,
        }
    })

    return (<ProtectedContent />)
}
