import Index from '../pages/Index'
import Invite from '../pages/Invite'
import Denied from '../pages/Denied'

import Settings from '../pages/UserSettings'
import NewWorkspace from '../pages/Workspace/New'
import WorkspaceSettings from '../pages/Workspace/Settings/WorkspaceSettings'
import WorkspaceIndex from '../pages/Workspace/Index'
import WorkspaceApplication from '../pages/Workspace/Application/WorkspaceApplication'
import WorkspaceLoginSuccess from '../pages/Workspace/Application/LoginSuccess'
import Dashboard from '../pages/Dashboard'


const routes = [
  {
    path: '/',
    component: <Index />,
    private: false,
  },
  {
    path: '/denied',
    component: <Denied />,
    private: false,
  },
  {
    path: '/invites/:invite_ref',
    component: <Invite />,
    private: false
  },
  {
    path: '/workspaces',
    component: <WorkspaceIndex />,
    private: true,
  },
  {
    path: '/workspace',
    component: <NewWorkspace />,
    private: true,
  },
  {
    path: '/workspaces/:workspace_ref/settings',
    component: <WorkspaceSettings />,
    private: true,
  },
  {
    path: '/workspaces/:workspace_ref/app',
    component: <WorkspaceApplication />,
    private: true,
  },
  {
    path: '/workspaces/:workspace_ref/login-success',
    component: <WorkspaceLoginSuccess />,
    private: true,
  },
  {
    path: '/workspaces/:workspace_ref',
    component: <WorkspaceApplication />,
    private: true,
  },
  {
    path: '/user-settings',
    component: <Settings />,
    private: true,
  },
  {
    path: '/dashboard',
    component: <Dashboard />,
    private: true,
  },

]

export default routes